import React, { useContext, useEffect, useState, useMemo } from 'react'
import Button from 'components/UI/Button'
import { observer } from 'mobx-react'
import storesContext from 'providers/storesContext'
import { useTranslation } from 'react-i18next'
import * as paths from '../../../routing/Paths'
import { useHistory, useLocation } from 'react-router'
import Album from 'shared/models/Album'
import { Photograph } from 'shared/models/Photograph'
import PackagePurchaseInfoModal from 'components/UI/PackagePurchaseInfoModal/PackagePurchaseInfoModal'
import { Event } from 'shared/models/Event'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

type DetailsPackageBarProps = {
  handleBuyPackage: () => void
  handleAddPackage: (price: number) => void
  photographBlocksByInterval: Map<string, Photograph[]>
  packagePrice: number
  album?: Album
  event?: Event
  tagValue?: string
  isFilteringByFaceRecognition: boolean
}

const DetailsPackageBar = ({
  handleBuyPackage,
  handleAddPackage,
  photographBlocksByInterval,
  packagePrice,
  album,
  event,
  tagValue,
  isFilteringByFaceRecognition,
}: DetailsPackageBarProps) => {
  const { t } = useTranslation()
  const { cartStore, authStore } = useContext(storesContext)!
  const history = useHistory()
  const location = useLocation()
  const [packageBarPosition, setPackageBarPosition] = useState(0)
  const [openPackagePurchaseInfoModal, setOpenPackagePurchaseInfoModal] = useState(false)
  const [isInCart, setIsInCart] = useState(false)

  const cartLines = cartStore.getLines()

  const totalLength = useMemo(
    () => Array.from(photographBlocksByInterval.values()).flat().length,
    [photographBlocksByInterval]
  )

  const currency = useMemo(() => event?.currency ?? album?.currency, [event, album])

  const photographs = Array.from(photographBlocksByInterval.values()).flat()

  const data = useMemo(() => {
    const packageInCart =
      photographs?.every((photograph) => cartStore.alreadyInCart(photograph.id)) ?? false
    const priceBeforeDiscounts = photographs.reduce((total, photo) => total + photo.price, 0)
    const discountAmountInPercentage = (1 - packagePrice / priceBeforeDiscounts) * 100

    return { packageInCart, priceBeforeDiscounts, discountAmountInPercentage }
  }, [photographs, packagePrice, cartStore])

  useEffect(() => {
    setIsInCart(data.packageInCart)
  }, [cartLines, photographBlocksByInterval, cartStore])

  const handleScroll = () => {
    const footer = document.querySelector('footer')
    const footerTop = footer!.getBoundingClientRect().top
    setPackageBarPosition(window.innerHeight >= footerTop ? window.innerHeight - footerTop : 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const openModal = () => setOpenPackagePurchaseInfoModal(true)
  const closeModal = () => setOpenPackagePurchaseInfoModal(false)

  const packageBarStyle: React.CSSProperties = {
    bottom: `${packageBarPosition}px`,
  }

  const title = (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row gap-2">
        <span className="text-base font-semibold text-black md:text-xl text-nowrap">
          {`${t('You’ve got')} ${totalLength} ${t('photos')}!`}
        </span>
        <span className="text-white text-sm bg-success p-0.5 rounded-md md:text-base text-nowrap">
          -{data.discountAmountInPercentage.toFixed()}%
        </span>
      </div>
      <span className="text-xs text-lumepic-light_black md:text-base text-nowrap">
        {t('All your photos, for the best price')}.
      </span>
    </div>
  )

  const beforeAndAfterPrices = (
    <span className="flex flex-col items-end md:w-full">
      <p className="font-light text-xs justify-end text-lumepic-grey line-through md:text-sm text-nowrap">{`${currency?.toUpperCase()} ${data.priceBeforeDiscounts.toFixed(
        2
      )}`}</p>
      <p className="font-extrabold text-black text-lg md:text-xl text-nowrap">{`${currency?.toUpperCase()} ${packagePrice.toFixed(
        2
      )}`}</p>
    </span>
  )

  const addButton = (
    <Button
      btnType="SecondaryAction"
      onClick={isInCart ? () => {} : () => handleAddPackage(packagePrice)}
      extraStyle={`text-sm w-full py-2 ${isInCart && 'opacity-60 cursor-not-allowed'}`}
    >
      <div className="flex justify-center items-center gap-2">
        <FontAwesomeIcon icon={faCartPlus} />
        <span className="md:text-base">{t('Add to Cart')}</span>
      </div>
    </Button>
  )

  const buyButton = (
    <Button
      isLoading={cartStore.isLoading}
      btnType="PrimaryAction"
      onClick={() =>
        authStore.isAuthenticated()
          ? openModal()
          : history.push({ pathname: paths.AUTH, state: { from: location } })
      }
      extraStyle="text-sm w-full py-2"
    >
      <div className="flex items-center gap-2">
        <FontAwesomeIcon icon={faCircleCheck} size="lg" />
        <span className="md:text-base">{`${t('Buy All Now')}`}</span>
      </div>
    </Button>
  )

  return (
    <>
      <div className="fixed z-20 w-full border-t bg-stone-200" style={packageBarStyle}>
        <div className="flex flex-col items-start md:items-center md:flex-row gap-3 md:gap-5 px-2 py-4 container mx-auto">
          <span className="hidden md:block">{title}</span>
          <div className="w-full flex justify-between md:hidden">
            {title}
            {beforeAndAfterPrices}
          </div>
          <div className="flex flex-col w-full gap-1.5 md:flex-row md:gap-4">
            {(authStore.isAuthenticated() || !isFilteringByFaceRecognition) && buyButton}
            {addButton}
          </div>
          <span className="hidden md:block">{beforeAndAfterPrices}</span>
        </div>
      </div>
      <PackagePurchaseInfoModal
        open={openPackagePurchaseInfoModal}
        onClose={closeModal}
        handleBuyClick={handleBuyPackage}
        handleAddBurstClick={() => handleAddPackage(packagePrice)}
        isBurstPurchase={false}
        album={album}
        eventName={event?.name}
        tagValue={tagValue}
        isLoading={cartStore.isLoading}
        isFaceRecognition={isFilteringByFaceRecognition}
      />
    </>
  )
}

export default observer(DetailsPackageBar)

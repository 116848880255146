import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { isNil } from 'lodash'
import { AxiosResponse } from 'axios'
import AlbumInterface from './Interfaces/Album/Album.interface'
import Album from '../shared/models/Album'
import PhotographInterface from './Interfaces/Photograph/Photograph.interface'
import { Photograph } from '../shared/models/Photograph'
import FeedInterface from './Interfaces/Feed/Feed.interface'
import { Paginated } from './Interfaces/Paginated'
import { PhotographerProfileInfo } from 'shared/models/PhotographerProfileInfo'
import MetricsInterface from './Interfaces/Feed/Metrics.interface'
import EventInterface from './Interfaces/Event/Event.interface'
import { Event } from 'shared/models/Event'
import { Tag } from 'shared/models/Tag'
import TagInterface, { TagKeys } from './Interfaces/Tag/Tag.interface'
import Tracker from 'shared/tracking'
import PhotographersMetricsInterface from './Interfaces/Feed/PhotographersMetrics.interface'

export type FetchFeedAlbumOptions = {
  isSearching?: boolean
  countryCode?: string
  albumIds?: string[]
  dateFrom?: string | null
  dateTo?: string | null
  locationId?: string
  activityId?: string
  eventId?: string
  ownerId?: string
  isFilteringByFaceRecognition?: boolean
  tagValue?: string | null
  includeHiddenEventAlbums?: boolean
  limit: number
  offset: number
  order?: FeedOrderQueryParams
}

type FetchFeedPhotographOptions = {
  filters?: { tagIds?: string[]; tagValue?: string; recognitionImageUrl?: string }
  pagination: {
    limit: number
    skip: number
  }
  userId?: string
}

type FeedAlbumResponse = {
  albums: Album[]
  count: number
  isSearching?: boolean
}

export enum FeedOrderFields {
  CREATED_AT = 'CREATED_AT',
  TAKEN_DATE = 'TAKEN_DATE',
  RATIO = 'RATIO',
  PHOTOGRAPHS_SOLD = 'PHOTOGRAPHS_SOLD',
  NET_SALES_AMOUNT_IN_USD = 'NET_SALES_AMOUNT_IN_USD',
  SALES_COUNT = 'SALES_COUNT',
  ACTIVITY = 'ACTIVITY',
}

export enum FeedOrderSorts {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type FeedOrderQueryParams = {
  field: FeedOrderFields
  sort: FeedOrderSorts
}

export type FeedAlbumsQueryParams = {
  order?: FeedOrderQueryParams[]
  pagination: {
    limit: Number
    skip: Number
  }
  filters: {
    countryCode?: string
    albumIds?: string[]
    locationId?: string
    activityId?: string
    eventId?: string
    dateFrom?: string | null
    dateTo?: string | null
    ownerId?: string
    disablePagination?: boolean
    includeHiddenEventAlbums?: boolean
  }
}

class FeedService {
  fetchFeedAlbums(options: FetchFeedAlbumOptions): Promise<FeedAlbumResponse> {
    const {
      countryCode,
      albumIds,
      limit,
      offset,
      order,
      locationId,
      activityId,
      eventId,
      dateFrom,
      dateTo,
      ownerId,
      isFilteringByFaceRecognition,
      tagValue,
      includeHiddenEventAlbums,
    } = options
    const queryParams: FeedAlbumsQueryParams = {
      order: [
        {
          field: order?.field || FeedOrderFields.TAKEN_DATE,
          sort: order?.sort || FeedOrderSorts.DESC,
        },
      ],
      pagination: {
        limit: Number(limit),
        skip: Number(offset),
      },
      filters: {},
    }
    if (!isNil(countryCode)) {
      queryParams.filters.countryCode = countryCode
    }
    if (!isNil(albumIds)) {
      queryParams.filters.albumIds = albumIds
    }
    if (!isNil(locationId)) {
      queryParams.filters.locationId = locationId
    }
    if (!isNil(activityId)) {
      queryParams.filters.activityId = activityId
    }
    if (!isNil(eventId)) {
      queryParams.filters.eventId = eventId
    }
    if (!isNil(dateFrom)) {
      queryParams.filters.dateFrom = dateFrom
    }
    if (!isNil(dateTo)) {
      queryParams.filters.dateTo = dateTo
    }
    if (!isNil(ownerId)) {
      queryParams.filters.ownerId = ownerId
    }
    if (isFilteringByFaceRecognition || (!isNil(tagValue) && tagValue !== '')) {
      queryParams.filters.disablePagination = true
    }
    if (includeHiddenEventAlbums) {
      queryParams.filters.includeHiddenEventAlbums = includeHiddenEventAlbums
    }
    return axios

      .get(paths.FEED, { params: queryParams })
      .then((res: AxiosResponse<FeedInterface>) => {
        const { count, albums } = res.data

        const albumModels = albums.map((a) => Album.init(a))
        return {
          count,
          albums: albumModels,
        }
      })
  }

  fetchPhotographerProfileInfo(alias: string): Promise<PhotographerProfileInfo> {
    return axios
      .get(paths.feedPhotographerProfileByAlias(alias))
      .then((res: AxiosResponse<PhotographerProfileInfo>) => {
        return res.data
      })
      .catch(parseAxiosErrorResponse)
  }

  //TODO: delete unused method
  fetchEventDetailsInformation(eventId: string): Promise<Event> {
    return axios
      .get(paths.feedEventDetails(eventId))
      .then((res: AxiosResponse<EventInterface>) => {
        const data = res.data
        return Event.init(data)
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchEventDetailsInformationByLandingPath(eventLandingPath: string): Promise<Event> {
    return axios
      .get(paths.feedEventDetailsByLandingPath(eventLandingPath))
      .then((res: AxiosResponse<EventInterface>) => {
        const data = res.data
        return Event.init(data)
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchTag(tagValue: string): Promise<Tag | null> {
    return axios
      .get(paths.tagByValue(tagValue))
      .then((res: AxiosResponse<TagInterface | null>) => {
        const data = res.data
        if (!isNil(data)) {
          return Tag.init(data)
        } else return null
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchMetrics(): Promise<MetricsInterface> {
    return axios
      .get(paths.HOME_METRICS)
      .then((res: AxiosResponse<MetricsInterface>) => {
        return res.data
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchPhotographersMetrics(): Promise<PhotographersMetricsInterface> {
    return axios
      .get(paths.PHOTOGRAPHERS_METRICS)
      .then((res: AxiosResponse<PhotographersMetricsInterface>) => {
        return res.data
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAlbumDetails(albumId: string): Promise<Album> {
    return axios
      .get(paths.albumDetails(albumId))
      .then((res: AxiosResponse<AlbumInterface>) => {
        const data = res.data
        return Album.init(data)
      })
      .catch(parseAxiosErrorResponse)
  }
  fetchAlbumTags(albumId: string): Promise<Tag[]> {
    return axios
      .get(paths.albumTags(albumId))
      .then((res: AxiosResponse<TagInterface[]>) => {
        const data = res.data
        return data.map((tag) => Tag.init(tag))
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchPhotograph(albumId: string, photographId: string, password?: string): Promise<Photograph> {
    return axios
      .get(paths.albumPhotograph(albumId, photographId), {
        headers: { password: password },
      })
      .then((res: AxiosResponse<PhotographInterface>) => {
        const data = res.data
        return Photograph.init(data)
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAlbumPhotographs(
    albumId: string,
    limit: Number,
    skip: Number,
    tagsIds?: string[],
    tagKey?: TagKeys,
    recognitionImageUrl?: string,
    userId?: string,
    password?: string
  ): Promise<Paginated<Photograph>> {
    const options: FetchFeedPhotographOptions = {
      pagination: {
        limit: Number(limit),
        skip: Number(skip),
      },
      userId: userId,
    }
    if (!isNil(tagsIds)) {
      options.filters = {
        tagIds: tagsIds,
      }
      if (tagsIds.length > 0) {
        Tracker.filterByTag(tagKey, userId, albumId, undefined)
      }
    }

    if (!isNil(recognitionImageUrl)) {
      options.filters = {
        ...options.filters,
        recognitionImageUrl: recognitionImageUrl,
      }
      Tracker.filterByFaceRecognition(userId, albumId, undefined)
    }

    return axios
      .get(paths.albumPhotographs(albumId), {
        params: options,
        headers: { password: password },
      })

      .then((res: AxiosResponse<Paginated<PhotographInterface>>) => {
        const result = res.data
        const models = result.items.map((p) => Photograph.init(p))
        return {
          items: models,
          count: result.count,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  downloadFreePhotograph(photographId: string) {
    return axios
      .get(paths.downloadFreePhotograph(photographId))
      .then((res: AxiosResponse) => {
        return res.data
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default FeedService

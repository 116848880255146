import React, { useContext, useState } from 'react'
import { faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import FaceRecognitionButtonDesktop from './components/FaceRecognitionButtonDesktop'
import TextRecognitionButtonDesktop from './components/TextRecognitionButtonDesktop'
import NoFilterButtonDesktop from './components/NoFilterButtonDesktop'
import SelfieUploader from 'components/UI/SelfieUploader/SelfieUploader'
import ProfileStore from 'containers/MyAccount/components/UpdateUser/ProfileStore'
import storesContext from 'providers/storesContext'
import Button from 'components/UI/Button'
import InputWrapper from 'components/UI/Forms/InputWrapper'
import EventDetailsStore from '../../EventDetailsStore'
import AlbumsViewFilterDesktop from './components/AlbumsViewFilterDesktop'
import DateAndTimeButtonDesktop from './components/DateAndTimeButtonDesktop'
import DateAndTimeFilterContent from 'components/AlbumDetails/components/DateAndTimeFilter/DateAndTimeFilterContent'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import { isNil } from 'lodash'

export enum filterTypes {
  FACE_RECOGNITION = 'FACE_RECOGNITION',
  TEXT = 'TEXT',
  DATE_AND_TIME = 'DATE_AND_TIME',
  ALBUMS_VIEW = 'ALBUMS_VIEW',
  NO_FILTERS = 'NO_FILTERS',
}

type EventDetailsFirstRenderDesktopProps = {
  filterByFaceRecognition: () => void
  filterByTagValue: () => void
  filterByDateAndTime?: () => void
  handleClearFilters: () => void
  handleShowAlbumsView: () => void
  eventDays?: DropdownOption[]
  timeOptions?: DropdownOption[]
  store: EventDetailsStore
}

const EventDetailsFirstRenderDesktop = ({
  filterByFaceRecognition,
  filterByTagValue,
  filterByDateAndTime,
  handleClearFilters,
  handleShowAlbumsView,
  eventDays,
  timeOptions,
  store,
}: EventDetailsFirstRenderDesktopProps) => {
  const { t } = useTranslation()
  const { authStore } = useContext(storesContext)!
  const [selectedAsProfilePicture, setSelectedAsProfilePicture] = useState(false)
  const [profileStore] = useState(() => new ProfileStore(authStore, true))

  const { tagValue, event } = store
  const defaultFilter = event.faceRecognitionFilter
    ? filterTypes.FACE_RECOGNITION
    : event.textFilter
    ? filterTypes.TEXT
    : filterTypes.NO_FILTERS
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter)

  const titleName =
    selectedFilter === filterTypes.FACE_RECOGNITION
      ? t('Upload a selfie')
      : selectedFilter === filterTypes.TEXT
      ? t('Enter a text')
      : selectedFilter === filterTypes.DATE_AND_TIME && !isNil(eventDays) && eventDays.length > 1
      ? t('Filter by date and time')
      : selectedFilter === filterTypes.DATE_AND_TIME
      ? t('Filter by hour')
      : selectedFilter === filterTypes.ALBUMS_VIEW
      ? t('Order by albums')
      : t('No filters')

  function handleChangeTagValue(tagValue: string) {
    store.setAlbumsView(false)
    store.setIsFilteringByFaceRecognition(false)
    store.setTagValue(tagValue)
    if (tagValue.trim() === '') {
      handleClearFilters()
    }
  }

  const handleFinishFaceRecognitionUpload = () => {
    if (authStore.isAuthenticated() && selectedAsProfilePicture) {
      profileStore.uploadImage().then(() => {
        const loggedUser = authStore.getLoggedUser()
        loggedUser.profilePictureUrl = profileStore.profilePictureUrl!
        profileStore.updateUser(loggedUser)
      })
    }
    filterByFaceRecognition()
  }

  const onSuccess = () => {
    selectedFilter === filterTypes.FACE_RECOGNITION
      ? handleFinishFaceRecognitionUpload()
      : selectedFilter === filterTypes.TEXT
      ? filterByTagValue?.()
      : selectedFilter === filterTypes.DATE_AND_TIME
      ? filterByDateAndTime?.()
      : selectedFilter === filterTypes.ALBUMS_VIEW
      ? handleShowAlbumsView?.()
      : handleClearFilters()
  }

  return (
    <div className="flex w-full bg-white p-3 gap-4">
      <div className="flex flex-col w-1/2 gap-3">
        <div className="flex gap-2">
          <span className="p-2 bg-primary_dark text-lumepic-light_black font-bold rounded-full h-6 w-6 flex items-center justify-center">
            1
          </span>
          <span>{t('Choose a search filter')}</span>
        </div>
        {event.faceRecognitionFilter && (
          <FaceRecognitionButtonDesktop
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        )}
        {event.textFilter && (
          <TextRecognitionButtonDesktop
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        )}
        {!isNil(eventDays) && !isNil(timeOptions) && !isNil(store.event.firstPhotographDate) && (
          <DateAndTimeButtonDesktop
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            eventDays={eventDays}
          />
        )}
        {event.albumsView && (
          <AlbumsViewFilterDesktop
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        )}
        <NoFilterButtonDesktop
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </div>
      <div className="w-1/2 flex flex-col gap-3">
        <div className="flex gap-2">
          <span className="p-2 bg-primary_dark text-lumepic-light_black font-bold rounded-full h-6 w-6 flex items-center justify-center">
            2
          </span>
          <span>{t('Follow instructions and find your photos')}</span>
        </div>
        <div className="w-full border rounded-lg">
          <h3 className="text-center text-xl font-semibold text-lumepic-light_black p-1.5 bg-lumepic-semi_medium_grey border-b">
            {titleName}
          </h3>
          <div className="p-3">
            {selectedFilter === filterTypes.FACE_RECOGNITION && event.faceRecognitionFilter && (
              <SelfieUploader
                store={profileStore}
                selectedAsProfilePicture={selectedAsProfilePicture}
                setSelectedAsProfilePicture={setSelectedAsProfilePicture}
              />
            )}
            {selectedFilter === filterTypes.TEXT && event.textFilter && (
              <>
                <div className="flex gap-1.5 items-start mb-4">
                  <FontAwesomeIcon icon={faInfoCircle} className="text-lumepic-grey mt-0.5" />
                  <p className="text-sm font-light">
                    {t(
                      'Type your unique number or word that distinguished your outfit on this event.'
                    )}
                  </p>
                </div>
                <InputWrapper
                  className="flex-grow-1"
                  onChange={handleChangeTagValue}
                  inputStore={tagValue}
                  placeholder={t('e.g. 2083').toString()}
                  onPressEnter={onSuccess}
                  extraClassNames="truncate"
                />
              </>
            )}
            {selectedFilter === filterTypes.DATE_AND_TIME &&
              !isNil(eventDays) &&
              !isNil(timeOptions) && (
                <DateAndTimeFilterContent
                  eventDays={eventDays || []}
                  timeOptions={timeOptions || []}
                  store={store}
                  onSuccess={filterByDateAndTime || (() => {})}
                  isDesktopFirstRenderView={true}
                />
              )}
            {selectedFilter === filterTypes.ALBUMS_VIEW && (
              <div className="flex gap-1.5 items-start mb-4">
                <FontAwesomeIcon icon={faInfoCircle} className="text-lumepic-grey mt-0.5" />
                <p className="text-lumepic-light_black text-lumepicSm">
                  {t(
                    'By choosing this option, the event’s content will be displayed by albums. This will allow you to identify easier in which album your photos will be.'
                  )}
                </p>
              </div>
            )}
            {selectedFilter === filterTypes.NO_FILTERS && (
              <div className="flex flex-col items-center gap-3 my-2">
                <FontAwesomeIcon icon={faWarning} className="text-4xl text-lumepic-grey" />
                <p className="text-lumepic-light_black text-center text-lumepicSm mx-10">
                  {t(
                    'Choosing this option will make it more difficult and time-consuming to locate your photos. Additionally, you will not have access to package discounts.'
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
        {selectedFilter !== filterTypes.DATE_AND_TIME && (
          <div className="w-full flex justify-center">
            <Button btnType="PrimaryAction" onClick={onSuccess} extraStyle="w-full">
              {t('Search')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(EventDetailsFirstRenderDesktop)

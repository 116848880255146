import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EventDetailsStore from '../EventDetailsStore'
import { isNil } from 'lodash'
import SearchBarDropdown, {
  DropdownOption,
} from 'components/UI/SearchBar/components/SearchBarDropdown'
import bibIcon from '../../../../assets/icons/v2/bibIcon.svg'
import selfieIcon from '../../../../assets/icons/v2/selfieIcon.svg'
import calendarIcon from '../../../../assets/icons/v2/calendarIcon.svg'
import clockIcon from '../../../../assets/icons/v2/clockIcon.svg'

type EventDetailSearchBarProps = {
  eventDetailsStore: EventDetailsStore
  handleOpenUploadSelfieModal: () => void
  handleOpenFilterByTagModal: () => void
  handleOpenFilterByDateAndTimeModal: () => void
  handleSearchByActivity: () => void
  searchInProgress: boolean
  isEventLongerThanFiveDays: boolean
}

const EventDetailSearchBar = ({
  eventDetailsStore,
  handleOpenUploadSelfieModal,
  handleOpenFilterByTagModal,
  handleOpenFilterByDateAndTimeModal,
  handleSearchByActivity,
  searchInProgress,
  isEventLongerThanFiveDays,
}: EventDetailSearchBarProps) => {
  const { t } = useTranslation()
  const { tagValue, isFilteringByFaceRecognition, event, dateRange } = eventDetailsStore

  const customButtonClasses = `
  w-full md:w-auto
  flex
  items-center
  justify-center
  p-3
  rounded-full
  border
  border-gray-300
  shadow-sm
  hover:shadow-md
  transition-all
  duration-200
  ease-in-out
  hover:bg-lumepic-light_black 
  hover:text-white
  group
`

  const landingPathsWithoutTagSearchBar = [
    'lanzamiento-renault-kardigan-uy-2024',
    'lego-fun-fest-uy-05-06',
    'nacional-liverpool-intermedio-2024',
    'nacional-liverpool-campenato-intermedio-2024',
  ]

  const eventDate = new Date(eventDetailsStore.event.date)
  const recognitionDateStart = new Date(2023, 10, 1)

  const showFaceRecognitionButton = eventDate > recognitionDateStart //TODO: add validation for processed album to show face rec button

  const handleChangeActivity = (option?: DropdownOption | null) => {
    eventDetailsStore.setActivity(option?.label)
    handleSearchByActivity()
  }

  const availableActivities = eventDetailsStore.isLoadingEventActivities
    ? []
    : eventDetailsStore.activities.map((activity) => ({
        value: activity.id,
        key: activity.id,
        label: activity.name,
      }))

  const multipleDayEvent = (() => {
    const firstDate = event.firstPhotographDate ? new Date(event.firstPhotographDate) : null
    const lastDate = event.lastPhotographDate ? new Date(event.lastPhotographDate) : null

    if (firstDate && lastDate) {
      return firstDate.toISOString().slice(0, 10) !== lastDate.toISOString().slice(0, 10)
    }
    return false
  })()

  const isFilteringByTagValue = tagValue.value?.length > 0 && searchInProgress

  return (
    <div className="flex flex-wrap gap-2 rounded-lg lg:pt-2">
      {isNil(event.activityId) && (
        <div className="flex flex-col md:w-1/6">
          <span className="mb-1 font-medium md:mt-2">{t('Sport')}</span>
          <SearchBarDropdown
            options={availableActivities}
            selectedValue={
              !isNil(eventDetailsStore.activity)
                ? {
                    value: eventDetailsStore.activity?.id,
                    label: eventDetailsStore.activity.name,
                  }
                : null
            }
            onChange={handleChangeActivity}
            dropdownLabel={t('Sport')}
            isLoading={eventDetailsStore.isLoadingEventActivities}
          />
        </div>
      )}
      <div className="flex flex-col md:flex-row w-full md:w-auto gap-2">
        {showFaceRecognitionButton && event.faceRecognitionFilter && (
          <button
            onClick={handleOpenUploadSelfieModal}
            className={` ${customButtonClasses} ${
              isFilteringByFaceRecognition
                ? 'bg-lumepic-light_black text-white'
                : 'bg-white text-gray-700'
            }`}
          >
            <img
              src={selfieIcon}
              alt="selfie"
              className={`w-7 h-7 mr-2 group-hover:filter group-hover:invert ${
                isFilteringByFaceRecognition ? 'filter invert' : ''
              }`}
            />
            {t('Face recognition')}
          </button>
        )}
        {!landingPathsWithoutTagSearchBar.includes(eventDetailsStore.event.landingPath) &&
          event.textFilter && (
            <button
              onClick={handleOpenFilterByTagModal}
              className={` ${customButtonClasses} ${
                isFilteringByTagValue
                  ? 'bg-lumepic-light_black text-white'
                  : 'bg-white text-gray-700'
              }`}
            >
              <img
                src={bibIcon}
                alt="bib-icon"
                className={`w-7 h-7 mr-2 group-hover:filter group-hover:invert ${
                  isFilteringByTagValue ? 'filter invert' : ''
                }`}
              />
              {t('Text recognition')}
            </button>
          )}
        {!isEventLongerThanFiveDays && (
          <button
            onClick={handleOpenFilterByDateAndTimeModal}
            className={`${customButtonClasses} ${
              !isNil(dateRange[0]) || !isNil(dateRange[1])
                ? 'bg-lumepic-light_black text-white'
                : 'bg-white text-gray-700'
            }`}
          >
            {multipleDayEvent ? (
              <>
                <img
                  src={calendarIcon}
                  alt="calendar-days"
                  className={`w-6 h-6 mr-2 group-hover:filter group-hover:invert ${
                    !isNil(dateRange[0]) || !isNil(dateRange[1]) ? 'filter invert' : ''
                  }`}
                />
                {t('Day and time')}
              </>
            ) : (
              <>
                <img
                  src={clockIcon}
                  alt="clock"
                  className={`w-6 h-6 mr-2 group-hover:filter group-hover:invert ${
                    !isNil(dateRange[0]) || !isNil(dateRange[1]) ? 'filter invert' : ''
                  }`}
                />
                {t('Hour')}
              </>
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default observer(EventDetailSearchBar)

import Album from './Album'
import { Photograph } from './Photograph'
import { CodeDiscount } from './CodeDiscount'
import { isNil } from 'lodash'
import { CodeDiscountUnit } from 'services/Interfaces/Discount/CodeDiscount.interface'
import { Currency } from '../util/Currency'

export enum PackageType {
  Burst = 'BURST',
  Tag = 'TAG',
}

export class CartLine {
  album?: Album
  photograph: Photograph
  currency: Currency
  packageType?: PackageType
  tagId?: string
  packagePrice?: number

  getPriceBeforeDiscounts(): number {
    return this.photograph.price || 0
  }

  get isPackage(): boolean {
    return this.packageType !== undefined
  }
}

export default class Cart {
  constructor() {
    this.lines = []
    this.discountTotal = 0
    this.codeDiscounts = []
  }
  lines: CartLine[]
  discountTotal: number
  currency: Currency | null
  codeDiscounts: CodeDiscount[]

  toString() {
    return JSON.stringify({
      ...this,
      lastModifiedAt: new Date(),
    })
  }

  /**
   * lines has <any> type because it comes from firebase AND/OR from localStorage. Setting a fixed type would break it.
   */
  static init(cart: any): Cart {
    const newCart = new Cart()
    try {
      newCart.currency = cart.currency
      newCart.codeDiscounts = cart.codeDiscounts
      for (const line of cart.lines) {
        const cartLine = new CartLine()
        if (line.album && line.photograph) {
          cartLine.album = Album.init(line.album)
          cartLine.photograph = Photograph.init(line.photograph)
          cartLine.currency = line.currency
          newCart.lines.push(cartLine)
        } else if (line.photograph && !isNil(line.packageType)) {
          cartLine.photograph = Photograph.init(line.photograph)
          cartLine.packageType = line.packageType
          if (!isNil(line.tagId)) {
            cartLine.tagId = line.tagId
          }
          cartLine.packagePrice = line.packagePrice
          cartLine.currency = line.currency
          newCart.lines.push(cartLine)
        }
      }
    } catch (e) {
      console.error(e)
    }
    return newCart
  }

  static clone(cart: Cart): Cart {
    const newCart = new Cart()
    newCart.currency = cart.currency
    newCart.codeDiscounts = cart.codeDiscounts
    for (const line of Array.from(cart.lines.values())) {
      const cartLine = new CartLine()
      if (line.album && line.photograph) {
        cartLine.album = Album.clone(line.album)
        cartLine.photograph = line.photograph
        cartLine.currency = line.currency
        newCart.lines.push(cartLine)
      } else if (line.photograph && line.isPackage) {
        cartLine.photograph = line.photograph
        cartLine.packageType = line.packageType
        cartLine.tagId = line.tagId
        cartLine.packagePrice = line.packagePrice
        cartLine.currency = line.currency
        newCart.lines.push(cartLine)
      }
    }
    return newCart
  }

  alreadyInCart(photographId: string): boolean {
    return this.lines.some((cartLine: CartLine) => cartLine.photograph?.id === photographId)
  }

  //TODO: rename ?
  photographFromPackageInCart(photographId: string): boolean {
    return this.lines
      .filter((cartLine: CartLine) => cartLine.isPackage)
      .some((cartLine: CartLine) => cartLine.photograph?.id === photographId)
  }

  reset() {
    this.lines = []
    this.discountTotal = 0
  }

  getPhotographs(): Photograph[] {
    return this.lines
      .flatMap((line) => line.photograph)
      .filter((photograph, index, self) => self.findIndex((p) => p.id === photograph.id) === index)
  }

  public getPhotographCount(albumId?: string, eventId?: string): number {
    if (!isNil(eventId)) {
      const cartLines = this.lines.filter((line) => line.album?.event.id === eventId)
      return cartLines
        .map((cartLine: CartLine) => {
          return cartLine.photograph
        })
        .filter((photograph) => photograph !== undefined).length
    } else if (!isNil(albumId)) {
      const cartLines = this.lines.filter((line) => line.album?.id === albumId)
      return cartLines
        .map((cartLine: CartLine) => {
          return cartLine.photograph
        })
        .filter((photograph) => photograph !== undefined).length
    } else {
      return this.lines.length
    }
  }

  /**
   * Returns the unique code discounts applied to the cart lines.
   */
  getCodeDiscounts(): CodeDiscount[] {
    return this.codeDiscounts
  }

  getCodeDiscountsByGroupLine(groupLines: CartLine[]): CodeDiscount[] {
    const albumOwnerIds = groupLines
      .map((line) => line.album?.owner.id)
      .filter((id) => id !== undefined) as string[]
    return this.codeDiscounts.filter((discount) => albumOwnerIds.includes(discount.ownerId))
  }

  getPriceBeforeDiscounts(): number {
    return this.lines
      .map((line) => line.getPriceBeforeDiscounts())
      .reduce((price, accum) => price + accum, 0)
  }

  getPriceAfterLineDiscounts(): number {
    return (
      this.lines
        .map((line) => line.getPriceBeforeDiscounts())
        .reduce((price, accum) => price + accum, 0) - this.getTotalDiscountsAmount()
    )
  }

  getTotalDiscountsAmount(): number {
    return (
      this.getCodeDiscountsAmount() +
      this.getQuantityDiscountAmount() +
      this.getPackagesDiscountAmount()
    )
  }

  getPackagesDiscountAmount(): number {
    const packagesTotalPrice = this.lines
      .filter((line) => line.isPackage)
      .map((line) => line.packagePrice!)
      .reduce((a, b) => a + b, 0)

    const photographsTotalPrice = this.lines
      .filter((line) => line.isPackage)
      .map((line) => line.photograph.price)
      .reduce((a, b) => a + b, 0)

    return photographsTotalPrice - packagesTotalPrice
  }

  getCodeDiscountsAmount(): number {
    const albums = this.lines
      .map((line) => line.album)
      .filter((album, index, self) => {
        return self.findIndex((a) => a?.id === album?.id) === index
      })
    let totalDiscountAmount = 0

    albums.map((album) => {
      const albumLines = this.lines.filter((line) => line.album?.id === album?.id)

      const numberOfPhotographs = this.getPhotographCount(album?.id)

      const discountPercentage = this.getQuantityDiscountPercentageEarned(
        numberOfPhotographs,
        album
      )

      const albumTotal = albumLines.map((line) => line.photograph.price).reduce((a, b) => a + b, 0)

      const codeDiscounts = this.codeDiscounts.filter(
        (codeDiscount) => codeDiscount.ownerId === album?.owner.id
      )

      if (codeDiscounts.length <= 0) {
        return 0
      } else {
        let albumTotalAfterQuantityDiscounts = 0
        let codeDiscountAmount = 0
        codeDiscounts.forEach((discount) => {
          switch (discount.unit) {
            case CodeDiscountUnit.PERCENTAGE:
              albumTotalAfterQuantityDiscounts =
                albumTotal - (albumTotal / 100) * discountPercentage
              codeDiscountAmount = albumTotalAfterQuantityDiscounts * (discount.amount / 100)
              totalDiscountAmount += codeDiscountAmount
              break
            case CodeDiscountUnit.CURRENCY:
              totalDiscountAmount += discount.amount
              break
            default:
              break
          }
        })
        return totalDiscountAmount
      }
    })
    return totalDiscountAmount
  }

  public getQuantityDiscountPercentageEarned(numberOfPhotographs: number, album?: Album): number {
    const quantityDiscount = album?.event.quantityDiscount || album?.quantityDiscount
    if (!isNil(quantityDiscount)) {
      const applicableDiscounts = quantityDiscount.discountPercentages.filter(
        (discountPercentage) => discountPercentage.numberOfPictures <= numberOfPhotographs
      )
      if (applicableDiscounts.length > 0) {
        return Math.max(
          ...applicableDiscounts.map((discountPercentage) => discountPercentage.discountPercentage)
        )
      } else {
        return 0
      }
    }
    return 0
  }

  getQuantityDiscountAmount(): number {
    const events = this.lines
      .map((line) => line.album?.event)
      .filter((event, index, self) => {
        return self.findIndex((e) => e?.id === event?.id) === index
      })

    let totalDiscountFromAlbumsRelatedToAnEvent = 0

    for (const event of events) {
      const albums = this.lines
        .filter((line) => line.album?.event.id === event?.id)
        .map((line) => line.album)
        .filter((album, index, self) => {
          return self.findIndex((a) => a?.id === album?.id) === index
        })

      for (const album of albums) {
        const albumLines = this.lines.filter((line) => line.album?.id === album?.id)

        const numberOfPhotographs = this.getPhotographCount(album?.id, event?.id)

        const discountPercentage = this.getQuantityDiscountPercentageEarned(
          numberOfPhotographs,
          album
        )

        const albumTotal = albumLines
          .map((line) => line.photograph.price)
          .reduce((a, b) => a + b, 0)

        totalDiscountFromAlbumsRelatedToAnEvent += (albumTotal / 100) * discountPercentage
      }
    }

    const albumsNotRelatedToAnEvent = this.lines
      .map((line) => line.album)
      .filter((album, index, self) => {
        return self.findIndex((a) => a?.id === album?.id && isNil(album?.event)) === index
      })

    let totalDiscount = 0

    for (const album of albumsNotRelatedToAnEvent) {
      const albumLines = this.lines.filter((line) => line.album?.id === album?.id)

      const numberOfPhotographs = this.getPhotographCount(album?.id)

      const discountPercentage = this.getQuantityDiscountPercentageEarned(
        numberOfPhotographs,
        album
      )

      const albumTotal = albumLines.map((line) => line.photograph.price).reduce((a, b) => a + b, 0)

      totalDiscount += (albumTotal / 100) * discountPercentage
    }
    return totalDiscount + totalDiscountFromAlbumsRelatedToAnEvent
  }

  public getPriceAfterDiscounts(): number {
    return this.getPriceBeforeDiscounts() - this.getTotalDiscountsAmount()
  }

  public getPriceAfterDiscountsByGroupLine(groupLines: CartLine[]): number {
    const priceBeforeDiscounts = groupLines.reduce(
      (total, line) => total + line.getPriceBeforeDiscounts(),
      0
    )
    const totalDiscountsAmount = this.getTotalDiscountsAmountByGroupLine(groupLines)
    return priceBeforeDiscounts - totalDiscountsAmount
  }

  public getQuantityDiscountAmountByGroupLine(groupLines: CartLine[]): number {
    const nonPackageLines = groupLines.filter((line) => !line.isPackage)

    const albums = nonPackageLines
      .map((line) => line.album)
      .filter((album, index, self) => {
        return self.findIndex((a) => a?.id === album?.id) === index
      })

    const numberOfPhotographs = nonPackageLines.length
    const discountPercentage = this.getQuantityDiscountPercentageEarned(
      numberOfPhotographs,
      albums[0]
    )

    const albumOrEventTotalWithoutPackages = nonPackageLines
      .map((line) => line.photograph.price)
      .reduce((a, b) => a + b, 0)

    return (albumOrEventTotalWithoutPackages / 100) * discountPercentage
  }

  public getCodeDiscountsAmountByGroupLine(groupLines: CartLine[]): number {
    let totalDiscountAmount = 0

    const onlyPackageLines = groupLines.every((line) => line.isPackage)

    if (onlyPackageLines) {
      if (this.codeDiscounts.length > 0) {
        totalDiscountAmount += this.codeDiscounts
          .filter((discount) => discount.unit === CodeDiscountUnit.CURRENCY)
          .reduce((sum, discount) => sum + discount.amount, 0)
      }
    } else {
      const nonPackageLines = groupLines.filter((line) => !line.isPackage)

      const albums = nonPackageLines
        .map((line) => line.album)
        .filter((album, index, self) => {
          return self.findIndex((a) => a?.id === album?.id) === index
        })

      albums.forEach((album) => {
        const albumLines = this.lines.filter((line) => line.album?.id === album?.id)
        const numberOfPhotographs = nonPackageLines.length
        const discountPercentage = this.getQuantityDiscountPercentageEarned(
          numberOfPhotographs,
          album
        )
        const albumTotal = albumLines
          .map((line) => line.photograph.price)
          .reduce((a, b) => a + b, 0)

        const codeDiscounts = this.codeDiscounts.filter(
          (codeDiscount) => codeDiscount.ownerId === album?.owner.id
        )

        if (codeDiscounts.length <= 0) {
          return 0
        } else {
          let albumTotalAfterQuantityDiscounts = 0
          let codeDiscountAmount = 0
          codeDiscounts.forEach((discount) => {
            switch (discount.unit) {
              case CodeDiscountUnit.PERCENTAGE:
                albumTotalAfterQuantityDiscounts =
                  albumTotal - (albumTotal / 100) * discountPercentage
                codeDiscountAmount = albumTotalAfterQuantityDiscounts * (discount.amount / 100)
                totalDiscountAmount += codeDiscountAmount
                break
              case CodeDiscountUnit.CURRENCY:
                totalDiscountAmount += discount.amount
                break
              default:
                break
            }
          })
        }
      })
    }
    return totalDiscountAmount
  }

  public getPackagesDiscountAmountByGroupLine(groupLines: CartLine[]): number {
    const packageLinesByTagId = groupLines.filter((line) => line.tagId)
    //TODO: const packageLinesByBurstTimestamp (burst timestamp is the id for burst packages)

    const linesGroupedByTagId = packageLinesByTagId.reduce((groups, line) => {
      const { tagId } = line
      if (!groups[tagId!]) {
        groups[tagId!] = []
      }
      groups[tagId!].push(line)
      return groups
    }, {})

    //TODO: const linesGroupedByBurstTimestamp

    const packagesTotalPrice = packageLinesByTagId[0]?.packagePrice ?? 0

    const photographsTotalPrice = packageLinesByTagId
      .filter((line) => line.isPackage)
      .map((line) => line.photograph)
      .flat()
      .map((photograph) => photograph.price)
      .reduce((a, b) => a + b, 0)

    return photographsTotalPrice - packagesTotalPrice * Object.keys(linesGroupedByTagId).length
  }

  getTotalDiscountsAmountByGroupLine(groupLines: CartLine[]): number {
    return (
      this.getCodeDiscountsAmountByGroupLine(groupLines) +
      this.getQuantityDiscountAmountByGroupLine(groupLines) +
      this.getPackagesDiscountAmountByGroupLine(groupLines)
    )
  }
}

import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Event } from 'shared/models/Event'
import QuantityDiscount from 'components/AlbumDetails/components/QuantityDiscount'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faCalendar, faLocationDot, faTag } from '@fortawesome/free-solid-svg-icons'
import { formatUtcToLocaleDateWithoutDay } from 'shared/utility'
import EventInformationSkeleton from './EventInformationSkeleton'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type EventInformationProps = {
  event: Event
  albumsCount: number
  photographCount: number
  isLoading: boolean
  filtersView?: boolean
}
const EventInformation = ({
  event,
  albumsCount,
  photographCount,
  isLoading,
  filtersView = false,
}: EventInformationProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'))

  if (isLoading) {
    return <EventInformationSkeleton />
  }
  return (
    <div>
      <div className="w-full flex flex-col rounded-lg lg:flex-row lg:justify-between items-center bg-white">
        <div className="flex gap-2 md:gap-4 rounded-md px-2 py-2.5 w-full">
          <img
            src={event.coverPhotographSrc}
            alt="Event Cover"
            className="object-cover object-center rounded-md"
            style={{ height: '150px', maxWidth: '150px' }}
          />
          <div className="flex flex-col gap-2">
            <span className="tracking-tight font-semibold text-base">
              {event.name?.toUpperCase()}
            </span>
            <div className="flex flex-col gap-1.5">
              <div className="flex gap-1 items-center">
                <FontAwesomeIcon icon={faCalendar} className="text-sm text-lumepic-dark_grey" />
                <span className="tracking-tight text-sm text-lumepic-grey">
                  {formatUtcToLocaleDateWithoutDay(event.date)}
                </span>
              </div>
              <div className="flex gap-1 items-center">
                <FontAwesomeIcon icon={faLocationDot} className="text-sm text-lumepic-dark_grey" />
                <span className="tracking-tight text-sm text-lumepic-grey">
                  {event.location?.spotName}
                </span>
              </div>
              <div className="flex gap-1 items-center">
                <FontAwesomeIcon icon={faBolt} className="text-sm text-lumepic-dark_grey" />
                <span className="tracking-tight text-sm text-lumepic-grey">
                  {event.activity?.name}
                </span>
              </div>
              {albumsCount > 0 && photographCount > 0 && (
                <div className="flex gap-1 items-start md:items-center">
                  <div
                    className="w-4 h-4 flex items-center justify-center bg-lumepic-dark_grey text-xs text-white"
                    style={{ borderRadius: '50%' }}
                  >
                    {albumsCount}
                  </div>
                  <span className="tracking-tight text-sm text-lumepic-grey">
                    {albumsCount === 1 ? t('album') : t('albums')} {''}
                    {t('and')} {photographCount}{' '}
                    {photographCount === 1 ? t('photograph') : t('photographs')}.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {!filtersView && !event.isFree && event.quantityDiscount && (
          <>
            {isMdScreen && (
              <div className="w-full lg:w-2/5">
                <QuantityDiscount event={event} />
              </div>
            )}
          </>
        )}
      </div>
      {!filtersView && !event.isFree && event.quantityDiscount && (
        <div className="my-2 md:hidden">
          <Accordion
            className="w-full md:hidden"
            onChange={(event, expanded) => setIsAccordionExpanded(expanded)}
            sx={{
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              marginTop: '1rem !important',
              '&.MuiAccordion-root': {
                borderRadius: '8px',
                overflow: 'hidden',
                '&:before': {
                  display: 'none',
                },
              },
              '& .MuiAccordionSummary-root': {
                backgroundColor: 'white',
                borderRadius: '8px',
                minHeight: '48px !important',
                '&.Mui-expanded': {
                  minHeight: '48px !important',
                },
              },
              '& .MuiAccordionSummary-content': {
                margin: '12px 0 !important',
                '&.Mui-expanded': {
                  margin: '12px 0 !important',
                },
              },
              '& .MuiAccordionDetails-root': {
                borderTop: isAccordionExpanded ? '1px solid #e0e0e0' : 'none',
                backgroundColor: 'white',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="flex items-center gap-1.5">
                <FontAwesomeIcon icon={faTag} className="text-lumepic-grey text-lg" />
                <header className="lg:text-lg">{t('Discounts')}</header>
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <QuantityDiscount event={event} />
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  )
}

export default observer(EventInformation)

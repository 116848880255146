import moment from 'moment'
import axios, { AxiosError } from 'axios'
import { isNil } from 'lodash'
import i18next, { TFunction } from 'i18next'
import { Purchase } from './models/Purchase'
import { QuantityDiscount } from './models/QuantityDiscount'
import { Photograph } from './models/Photograph'
import FeatureFlagsStore from 'stores/FeatureFlagsStore'
import { TagKeys } from 'services/Interfaces/Tag/Tag.interface'
import AlbumDetailsStore from 'components/AlbumDetails/AlbumDetailsStore'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import {
  AlbumTableColumn,
  AlbumTableColumnId,
} from 'services/Interfaces/Album/AlbumTableColumn.interface'
import { MaxPhotographersPerPackage, MaxPhotosForPackageWithTextTag } from './util/constants'

export const getCountryList = () => {
  // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
  return [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    {
      code: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
      phone: '500',
    },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    {
      code: 'KP',
      label: "Korea, Democratic People's Republic of",
      phone: '850',
    },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    {
      code: 'MK',
      label: 'Macedonia, the Former Yugoslav Republic of',
      phone: '389',
    },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  ]
}

export const unaccentMap = {
  á: 'a',
  é: 'e',
  í: 'i',
  ó: 'o',
  ú: 'u',
  Á: 'a',
  É: 'e',
  Í: 'i',
  Ó: 'o',
  Ú: 'u',
}

export const isMobileDevice = () => {
  let check = false
  // eslint-disable-next-line no-useless-escape
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor)
  return check
}

//TODO: we should delete this and use withErrorHandler hook.
export const parseAxiosErrorResponse = (error: any) => {
  if (error.response) {
    if (error.response.status === 401) {
      throw new Error('Debes iniciar sesión.')
    }
    // Request made and server responded
    throw new Error(error.response.data.message)
  } else if (error.request) {
    // The request was made but no response was received
    throw new Error('Service Unavailable')
  } else {
    // Something happened in setting up the request that triggered an Error
    throw new Error(`Unexpected Error: ${JSON.stringify(error)}`)
  }
}

export const parseAxiosErrorCode = (error: Error | AxiosError): string => {
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.status === 401) {
        return 'UNAUTHORIZED'
      }
      // Request made and server responded
      return error.response.data?.error?.code || error.response.data?.message || 'UNEXPECTED_ERROR'
    } else if (error.request) {
      // The request was made but no response was received
      return 'SERVICE_UNAVAILABLE'
    } else {
      // Something happened in setting up the request that triggered an Error
      return `Unexpected Error: ${JSON.stringify(error)}`
    }
  } else {
    // Trying to parse an error that is not axios.
    return `Unexpected Error: ${JSON.stringify(error)}`
  }
}

export const formatUtcToLocaleDate = (date: Date) => {
  const language = i18next.language
  const momentDate = moment.utc(date).locale(language)

  let formattedDate
  if (language === 'en') {
    formattedDate = momentDate.format('dddd MM/DD/YYYY')
  } else {
    formattedDate = momentDate.format('dddd DD/MM/YYYY')
  }

  formattedDate = formattedDate.replace(/^\w/, (c) => c.toUpperCase())

  return formattedDate
}

export const getDateDay = (date: Date) => {
  const dateDay = date.getDay()
  return dateDay
}

export const formatUtcToLocaleDateWithoutDay = (date: Date) => {
  const language = i18next.language
  const momentDate = moment.utc(date).locale(language)

  let formattedDate
  if (language === 'en') {
    formattedDate = momentDate.format('MM/DD/YYYY')
  } else {
    formattedDate = momentDate.format('DD/MM/YYYY')
  }

  return formattedDate
}

export const isEmptyObject = (obj: any) => {
  return isNil(obj) || Object.keys(obj).length === 0
}

export const formatBits = (bits: number, decimals = 2): string => {
  if (!+bits) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bits', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bits) / Math.log(k))

  return `${parseFloat((bits / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const formatThousandsAmount = (value: number, decimals?: number): string => {
  const language = i18next.language

  const formattedNumber = new Intl.NumberFormat(language, {
    style: 'decimal',
    minimumFractionDigits: decimals ?? 2,
    maximumFractionDigits: decimals ?? 2,
    useGrouping: true,
  }).format(value)

  return formattedNumber
}

export function capitalizeFirstLetter(word): string {
  if (word.length === 0) {
    return ''
  }
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function formatPhotographCountText(photographCount: number, t: TFunction): string {
  if (photographCount === 1) {
    return `1 ${t('photograph')}`
  } else {
    return `${photographCount} ${t('photos')}`
  }
}

export async function getCountryCodeFromLatLng(): Promise<
  { countryCode: string; lat: number; lng: number } | undefined
> {
  let data: { countryCode: string; lat: number; lng: number } | undefined
  try {
    const geolocation = navigator.geolocation
    await geolocation.getCurrentPosition(
      (position) => {
        // Get country code from Google Maps api by coordinates
        const geocoder = new google.maps.Geocoder()
        const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            const countryCode = results
              ?.find((result) => result.types.includes('country'))
              ?.address_components.find((addressComponent) =>
                addressComponent.types.includes('country')
              )?.short_name
            if (!isNil(countryCode)) {
              data = {
                countryCode: countryCode || 'US',
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }
            }
          } else {
            console.log('Geocoder failed due to: ' + status)
          }
        })
      },
      () => {
        return
      }
    )
  } catch (error) {
    console.log('Could not get country code from geolocation')
  }
  return data
}

export const getFeesAmountGenerator = (userId: string, sale: Purchase) => {
  let amount = 0
  const payment = sale.payments?.length > 0 ? sale.payments[0] : null
  if (!isNil(payment) && !isNil(payment.fees)) {
    if (payment.orderType === 'mercadopago') {
      payment.fees?.map((fee) => (amount += fee.amount))
    } else {
      const userOwnedLineItems = sale.lineItems.filter(
        (lineItem) => lineItem.album && lineItem.album.ownerId && lineItem.album.ownerId === userId
      )
      const userOwnedFees = payment.fees.filter((fee) =>
        userOwnedLineItems.some((lineItem) => lineItem.id === fee.lineItemId)
      )

      userOwnedFees.forEach((fee) => {
        if (fee.currency !== sale.currency && !isNil(payment.exchangeRate)) {
          amount += fee.amount / payment.exchangeRate
        } else {
          amount += fee.amount
        }
      })
    }
  }

  return amount
}

export const updatePackageBarVisibility = (
  filtersApplied: boolean,
  store: AlbumDetailsStore | EventDetailsStore,
  handlePackagePurchaseEnabled: string | number | boolean,
  totalPhotographsPrice: number,
  totalPhotographsPriceAfterDiscounts: number,
  minNumberOfPhotosForQtyDiscount: number | null,
  ownerIdsInPackageSize?: number
) => {
  let showBuyPackageButton = false
  let buyNowPrice: number | null = null

  let entity
  if (store instanceof AlbumDetailsStore) {
    entity = store.album
  } else if (store instanceof EventDetailsStore) {
    entity = store.event
  }

  if (filtersApplied && store.photographs.length > 0) {
    const tag = store.photographs
      .map((photograph) => photograph.tags)
      .reduce((acc, tags) => acc.filter((tag) => tags.some((t) => t.id === tag.id)))
      .find((tag) => tag)

    const isTextTag = !isNil(tag) && tag.key === TagKeys.Text
    const MaxPhotosPerPackageWithTextTag =
      isTextTag && store.photographs.length >= MaxPhotosForPackageWithTextTag

    const packageIsUnderPhotographerLimit = !isNil(ownerIdsInPackageSize)
      ? ownerIdsInPackageSize <= MaxPhotographersPerPackage
      : true

    const isPackagePurchaseEnabled =
      !isNil(entity.defaultPackagePrice) &&
      typeof entity.defaultPackagePrice === 'number' && // TODO: Delete when fixing all defaultPackagePrice fields with value NaN.
      handlePackagePurchaseEnabled &&
      !MaxPhotosPerPackageWithTextTag &&
      packageIsUnderPhotographerLimit

    const isQtyDiscountPurchaseEnabled =
      !isNil(entity.quantityDiscount) &&
      !isNil(minNumberOfPhotosForQtyDiscount) &&
      store.photographs.length >= minNumberOfPhotosForQtyDiscount

    switch (true) {
      case !isPackagePurchaseEnabled && !isQtyDiscountPurchaseEnabled:
        showBuyPackageButton = false
        buyNowPrice = null
        break

      case isPackagePurchaseEnabled && !isQtyDiscountPurchaseEnabled:
        if (totalPhotographsPrice > entity.defaultPackagePrice) {
          buyNowPrice = entity.defaultPackagePrice
          showBuyPackageButton = true
        }
        break

      case !isPackagePurchaseEnabled && isQtyDiscountPurchaseEnabled:
        if (totalPhotographsPrice > totalPhotographsPriceAfterDiscounts) {
          buyNowPrice = totalPhotographsPriceAfterDiscounts
          showBuyPackageButton = true
        }
        break

      case isPackagePurchaseEnabled && isQtyDiscountPurchaseEnabled:
        if (
          totalPhotographsPriceAfterDiscounts > entity.defaultPackagePrice &&
          entity.defaultPackagePrice < totalPhotographsPrice
        ) {
          showBuyPackageButton = true
          buyNowPrice = entity.defaultPackagePrice
        } else if (
          totalPhotographsPriceAfterDiscounts < entity.defaultPackagePrice &&
          totalPhotographsPriceAfterDiscounts < totalPhotographsPrice
        ) {
          showBuyPackageButton = true
          buyNowPrice = totalPhotographsPriceAfterDiscounts
        }
        break

      default:
        showBuyPackageButton = false
        buyNowPrice = null
    }
  } else {
    showBuyPackageButton = false
    buyNowPrice = null
  }

  return {
    showBuyPackageButton,
    buyNowPrice,
  }
}

export const calculateTotalPhotographsPrice = (photographs: Photograph[]) => {
  return photographs.reduce((total, photo) => total + photo.price, 0)
}

export const isPackagePurchaseEnabledAfterQtyDiscountCalc = (
  totalPhotographsPrice: number,
  totalPhotographsPriceAfterDiscounts: number,
  defaultPackagePrice?: number,
  quantityDiscount?: QuantityDiscount
): boolean => {
  return !isNil(quantityDiscount) && !isNil(defaultPackagePrice)
    ? totalPhotographsPriceAfterDiscounts > defaultPackagePrice
    : !isNil(defaultPackagePrice) && totalPhotographsPrice > defaultPackagePrice
}

export const getFirstNumberOfPicturesForDiscount = (quantityDiscount: QuantityDiscount) => {
  if (quantityDiscount.discountPercentages.length > 0) {
    return quantityDiscount.discountPercentages[0].numberOfPictures
  }
  return null
}

export const getQtyDiscountPercentageEarned = (
  quantityDiscount: QuantityDiscount | null,
  photographs: Photograph[]
) => {
  if (!isNil(quantityDiscount)) {
    const applicableDiscounts = quantityDiscount.discountPercentages.filter(
      (discountPercentage) => discountPercentage.numberOfPictures <= photographs.length
    )
    if (applicableDiscounts.length > 0) {
      return Math.max(
        ...applicableDiscounts.map((discountPercentage) => discountPercentage.discountPercentage)
      )
    }
  }
  return 0
}

export const getFeatureFlagValue = (
  store: FeatureFlagsStore,
  featureFlagKey: string,
  defaultValue: boolean
) => {
  try {
    const value = store.getFeatureFlagValue(featureFlagKey)
    return isNil(value) ? defaultValue : value
  } catch (error) {
    return defaultValue
  }
}

export const SetPageTitle = (title: string) => {
  document.title = `Lumepic | ${title}`
}

export const parseDate = (dateString) => {
  const parsedDate = moment(dateString, 'DD-MM-YYYY', true)
  return parsedDate.isValid() ? parsedDate.toDate() : null
}

const baseDesktopColumns: AlbumTableColumn[] = [
  { id: AlbumTableColumnId.Date, label: 'Date', minWidth: 100 },
  { id: AlbumTableColumnId.Location, label: 'Spot', minWidth: 100 },
  { id: AlbumTableColumnId.Description, label: 'Description', minWidth: 100 },
  { id: AlbumTableColumnId.Activity, label: 'Activity', minWidth: 100 },
  { id: AlbumTableColumnId.PhotographCount, label: 'Photographs', minWidth: 30 },
  { id: AlbumTableColumnId.Status, label: 'Status', minWidth: 100 },
  { id: AlbumTableColumnId.Actions, label: 'Actions', minWidth: 10 },
]

const baseMobileColumns: AlbumTableColumn[] = [
  { id: AlbumTableColumnId.Date, label: 'Date', minWidth: 100 },
  { id: AlbumTableColumnId.Location, label: 'Spot', minWidth: 100 },
  { id: AlbumTableColumnId.Activity, label: 'Activity', minWidth: 100 },
  { id: AlbumTableColumnId.Actions, label: 'Actions', minWidth: 10 },
]

const photographerDashboardExtraDesktopColumns: AlbumTableColumn[] = [
  { id: AlbumTableColumnId.SalesCount, label: 'Sales Count', minWidth: 10 },
  { id: AlbumTableColumnId.NetSalesAmountInUsd, label: 'Net Sales Earnings (USD)', minWidth: 30 },
  { id: AlbumTableColumnId.SoldPhotographsCount, label: 'Sold Photographs', minWidth: 30 },
  {
    id: AlbumTableColumnId.Ratio,
    label: 'Ratio',
    description: 'Ratio of photographs sold to photographs uploaded',
    minWidth: 100,
  },
]

const photographerDashboardExtraMobileColumns: AlbumTableColumn[] = [
  { id: AlbumTableColumnId.NetSalesAmountInUsd, label: 'Net Sales Earnings (USD)', minWidth: 30 },
  {
    id: AlbumTableColumnId.Ratio,
    label: 'Ratio',
    minWidth: 30,
  },
]

const filteredDesktopColumns = baseDesktopColumns.filter(
  (col) =>
    col.id !== AlbumTableColumnId.Status &&
    col.id !== AlbumTableColumnId.Actions &&
    col.id !== AlbumTableColumnId.Location
)

const filteredMobileColumns = baseMobileColumns.filter(
  (col) =>
    col.id !== AlbumTableColumnId.Status &&
    col.id !== AlbumTableColumnId.Actions &&
    col.id !== AlbumTableColumnId.Location
)

export const desktopColumns: AlbumTableColumn[] = [...baseDesktopColumns]

export const mobileColumns: AlbumTableColumn[] = [...baseMobileColumns]

export const photographerDashboardDesktopColumns: AlbumTableColumn[] = [
  ...filteredDesktopColumns,
  ...photographerDashboardExtraDesktopColumns,
]

export const photographerDashboardMobileColumns: AlbumTableColumn[] = [
  ...filteredMobileColumns,
  ...photographerDashboardExtraMobileColumns,
]

export const myAlbumsColumns = isMobileDevice() ? mobileColumns : desktopColumns

export const photographerDashboardColumns = isMobileDevice()
  ? photographerDashboardMobileColumns
  : photographerDashboardDesktopColumns

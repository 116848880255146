import storesContext from 'providers/storesContext'
import React, { useContext, useEffect } from 'react'
import Album from 'shared/models/Album'
import { Photograph } from 'shared/models/Photograph'
import AddToCartButton from '../../../containers/Cart/components/AddToCartButton'
import { observer } from 'mobx-react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Spinner from 'components/UI/Spinner'
import { isNil } from 'lodash'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import AlbumDetailsStore from '../AlbumDetailsStore'
import PackageLabel from 'components/UI/PackageLabel/PackageLabel'

type DetailsPhotographCardProps = {
  photograph: Photograph
  album: Album
  eventDetailsStore?: EventDetailsStore
  albumDetailsStore?: AlbumDetailsStore
  setPhotograph: (photographId: string) => void
  downloadFreePhotograph: (photographId: string) => void
}

const DetailsPhotographCard = ({
  photograph,
  album,
  eventDetailsStore,
  albumDetailsStore,
  setPhotograph,
  downloadFreePhotograph,
}: DetailsPhotographCardProps) => {
  const { t } = useTranslation()
  const { cartStore } = useContext(storesContext)!

  if (cartStore.isLoading) {
    return <Spinner />
  }

  const isFilteringByFaceRecognition =
    (!isNil(eventDetailsStore) && eventDetailsStore.isFilteringByFaceRecognition) ||
    (!isNil(albumDetailsStore) && albumDetailsStore.isFilteringByFaceRecognition)

  const isFilteringByTagValue =
    (!isNil(eventDetailsStore) && eventDetailsStore.tagIds.length > 0) ||
    (!isNil(albumDetailsStore) && albumDetailsStore.tagIds.length > 0)

  useEffect(() => {}, [isFilteringByFaceRecognition, isFilteringByTagValue])

  const photographAlreadyInCart = cartStore.alreadyInCart(photograph.id)

  const photographFromPackageInCart =
    photographAlreadyInCart && cartStore.photographFromPackageInCart(photograph.id)

  const notInCartClasses =
    'row-span-5 h-48 bg-cover rounded-lg bg-center bg-no-repeat hover:cursor-pointer'
  const alreadyInCartClasses =
    'row-span-5 h-48 bg-cover rounded-lg bg-center bg-no-repeat hover:cursor-pointer border-2 border-lumepic-black'

  const photographInCartBelongsToPackage =
    'row-span-5 h-48 bg-cover rounded-lg bg-center bg-no-repeat hover:cursor-pointer border-2 border-lumepic-black'

  return (
    <div className="grid col-span-3 grid-rows-6 sm:col-span-2 lg:col-span-1 animate-fadeIn">
      <div
        data-photograph-id={photograph.id}
        className={
          photographAlreadyInCart && photographFromPackageInCart
            ? photographInCartBelongsToPackage
            : photographAlreadyInCart && !photographFromPackageInCart
            ? alreadyInCartClasses
            : notInCartClasses
        }
        style={{ backgroundImage: `url(${photograph.thumbnailUrl})` }}
        onClick={() => setPhotograph(photograph.id)}
      >
        {photographFromPackageInCart && (
          <div className="w-full flex">
            <PackageLabel />
          </div>
        )}
      </div>

      {!isNil(album) && album.isFree ? (
        <div
          className="flex items-center justify-end row-span-1 mt-1 cursor-pointer gap-x-3"
          onClick={() => downloadFreePhotograph(photograph.id)}
        >
          <Tooltip title={t('Download')} arrow>
            <i>
              <FontAwesomeIcon icon={faDownload} />
            </i>
          </Tooltip>
        </div>
      ) : !isNil(album) ? (
        <div
          className={`flex items-center justify-end row-span-1 mt-1 gap-x-3 ${
            photographFromPackageInCart && 'hidden'
          }`}
        >
          <span>
            {album.currency?.toUpperCase()} {photograph.price}
          </span>
          <AddToCartButton photograph={photograph} album={album} />
        </div>
      ) : (
        <div
          className={`flex items-center justify-end row-span-1 mt-1 gap-x-3 ${
            photographFromPackageInCart && 'hidden'
          }`}
        >
          <span>
            {eventDetailsStore?.event.currency?.toUpperCase()} {photograph.price}
          </span>
          <AddToCartButton photograph={photograph} album={album} />
        </div>
      )}
    </div>
  )
}

export default observer(DetailsPhotographCard)

import axios from './axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { AxiosResponse } from 'axios'
import PhotographerMetricsInterface from './Interfaces/User/PhotographerMetrics.interface'
// import { Currency } from 'shared/util/Currency'
import { isNil } from 'lodash'
import Album from 'shared/models/Album'
import FeedInterface from './Interfaces/Feed/Feed.interface'
import {
  FeedAlbumsQueryParams,
  FeedOrderFields,
  FeedOrderSorts,
  FetchFeedAlbumOptions,
} from './FeedService'

type FetchPhotographerMetricsOptions = {
  dateFrom?: string | null
  dateTo?: string | null
  activityId?: string
  locationId?: string
  eventId?: string
}

type FetchAlbumsInsightsResponse = {
  albums: Album[]
  count: number
}

class DashboardService {
  async fetchPhotographerMetrics(
    options: FetchPhotographerMetricsOptions,
    token: string
  ): Promise<PhotographerMetricsInterface> {
    const { dateFrom, dateTo, activityId, locationId, eventId } = options
    const queryParams: FetchPhotographerMetricsOptions = {}
    if (!isNil(dateFrom)) {
      queryParams.dateFrom = dateFrom
    }
    if (!isNil(dateTo)) {
      queryParams.dateTo = dateTo
    }
    if (!isNil(activityId)) {
      queryParams.activityId = activityId
    }
    if (!isNil(locationId)) {
      queryParams.locationId = locationId
    }
    if (!isNil(eventId)) {
      queryParams.eventId = eventId
    }
    return axios
      .get(paths.DASHBOARD_PHOTOGRAPHER_METRICS, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async fetchPhotographerSalesInsights(
    token: string
  ): Promise<{ year: number; month: number; salesCount: number; salesAmountInUsd: number }[]> {
    return axios
      .get(paths.DASHBOARD_PHOTOGRAPHER_SALES_INSIGHTS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async fetchPhotographerPublishedAlbums(
    token: string
  ): Promise<{ year: number; month: number; albumCount: number }[]> {
    return axios
      .get(paths.DASHBOARD_PHOTOGRAPHER_PUBLISHED_ALBUMS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async fetchPhotographerSalesByType(token: string): Promise<{
    independentAlbumSales: { salesAmountInUsd: number; salesCount: number }
    eventAlbumSales: { salesAmountInUsd: number; salesCount: number }
  }> {
    return axios
      .get(paths.DASHBOARD_SALES_BY_TYPE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async fetchPhotographerSalesByActivity(
    token: string
  ): Promise<{ activity: string; salesAmountInUsd: number; salesCount: number }[]> {
    return axios
      .get(paths.DASHBOARD_PHOTOGRAPHER_SALES_BY_ACTIVITY, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async fetchPhotographerAlbumsInsights(
    token: string,
    options: FetchFeedAlbumOptions
  ): Promise<FetchAlbumsInsightsResponse> {
    const { order, limit, offset } = options
    const queryParams: FeedAlbumsQueryParams = {
      order: [
        {
          field: order?.field || FeedOrderFields.TAKEN_DATE,
          sort: order?.sort || FeedOrderSorts.DESC,
        },
      ],
      pagination: {
        limit: limit,
        skip: offset,
      },
      filters: {},
    }
    return axios
      .get(paths.DASHBOARD_ALBUMS_INSIGHTS, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: AxiosResponse<FeedInterface>) => {
        const { albums, count } = res.data
        const albumModels = albums.map((a) => Album.init(a))
        return {
          albums: albumModels,
          count,
        }
      })
      .catch(parseAxiosErrorResponse)
  }
}

export default DashboardService

import React from 'react'
import EventDetailsFirstRenderMobile from './EventDetailsFirstRenderMobile'
import { observer } from 'mobx-react'
import EventDetailsFirstRenderDesktop from './EventDetailsFirstRenderDesktop'
import EventDetailsStore from '../../EventDetailsStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'

type EventDetailsFirstRenderProps = {
  handleOpenUploadSelfieModal: () => void
  filterByFaceRecognition: () => void
  handleOpenFilterByTagModal: () => void
  filterByTagValue: () => void
  handleClearFilters: () => void
  handleShowAlbumsView: () => void
  store: EventDetailsStore
  isEventLongerThanFiveDays: boolean
  filterByDateAndTime?: () => void
  handleOpenFilterByDateAndTimeModal?: () => void
  eventDays?: DropdownOption[]
  timeOptions?: DropdownOption[]
}

const EventDetailsFirstRender = ({
  handleOpenFilterByTagModal,
  handleOpenUploadSelfieModal,
  filterByDateAndTime,
  handleOpenFilterByDateAndTimeModal,
  filterByFaceRecognition,
  filterByTagValue,
  handleShowAlbumsView,
  handleClearFilters,
  eventDays,
  timeOptions,
  store,
  isEventLongerThanFiveDays,
}: EventDetailsFirstRenderProps) => {
  const { t } = useTranslation()
  return (
    <div className="mt-4">
      <div className="border-b-4 border-primary_dark bg-white flex items-center gap-1.5 py-1.5 px-1.5 lg:px-2.5">
        <FontAwesomeIcon icon={faFilter} className="text-lumepic-grey text-lg ml-1" />
        <header className="mt-px text-lg">{t('Search')}</header>
      </div>
      <div className="lg:hidden">
        <EventDetailsFirstRenderMobile
          handleOpenFilterByTagModal={handleOpenFilterByTagModal}
          handleOpenUploadSelfieModal={handleOpenUploadSelfieModal}
          handleShowAlbumsView={handleShowAlbumsView}
          handleClearFilters={handleClearFilters}
          handleOpenFilterByDateAndTimeModal={
            isEventLongerThanFiveDays ? undefined : handleOpenFilterByDateAndTimeModal
          }
          eventDays={isEventLongerThanFiveDays ? undefined : eventDays}
          timeOptions={isEventLongerThanFiveDays ? undefined : timeOptions}
          store={store}
        />
      </div>
      <div className="hidden lg:block">
        <EventDetailsFirstRenderDesktop
          filterByFaceRecognition={filterByFaceRecognition}
          filterByTagValue={filterByTagValue}
          filterByDateAndTime={isEventLongerThanFiveDays ? undefined : filterByDateAndTime}
          handleClearFilters={handleClearFilters}
          handleShowAlbumsView={handleShowAlbumsView}
          eventDays={isEventLongerThanFiveDays ? undefined : eventDays}
          timeOptions={isEventLongerThanFiveDays ? undefined : timeOptions}
          store={store}
        />
      </div>
    </div>
  )
}

export default observer(EventDetailsFirstRender)

import { makeAutoObservable, runInAction } from 'mobx'
import Album from 'shared/models/Album'
import { Photograph } from 'shared/models/Photograph'
import AuthStore from 'stores/AuthStore'
import { EventService } from 'services/EventService'
import FeedService, { FeedOrderFields, FeedOrderSorts } from 'services/FeedService'
import { Event } from 'shared/models/Event'
import InputStore from 'shared/store/InputStore'
import { string } from 'yup'
import { Activity } from 'shared/models/Activity'
import { isNil } from 'lodash'
import { TagKeys } from 'services/Interfaces/Tag/Tag.interface'

const DefaultAlbumsPerPage = 100

const DefaultPhotographsPerPage = 30
const DefaultFilteredPhotographsPerEvent = 150

export default class EventDetailsStore {
  public eventId: string
  public event: Event
  public albums: Album[]
  public albumsPerPage: number
  public albumsCount: number
  public photographs: Photograph[]
  public tagIds: string[]
  public tagValue: InputStore<string>
  public isFilteringByFaceRecognition: boolean
  public albumsView: boolean
  public photographCount: number
  public dateRange: [string | null, string | null]
  public isLoadingEventDetails: boolean
  public isLoadingEventPhotographs: boolean
  public isLoadingEventAlbums: boolean
  public isLoadingFreePhotograph: boolean
  public isLoadingEventActivities: boolean
  public eventNotFound: boolean
  public page: number
  public photographsPerPage: number
  public error: any
  public activity: Activity | null
  public activities: Activity[]
  private tagKey?: TagKeys
  private readonly feedService: FeedService
  private readonly eventService: EventService
  private readonly authStore: AuthStore
  constructor(
    private readonly eventLandingPath: string,
    authStore: AuthStore,
    initialTagIds?: string[],
    initialTagValue?: string
  ) {
    this.resetStore()
    initialTagValue && this.tagValue.setValue(initialTagValue)
    makeAutoObservable(this)
    this.authStore = authStore
    this.eventService = new EventService()
    this.feedService = new FeedService()
  }
  resetStore() {
    this.eventId = ''
    this.event = new Event()
    this.eventNotFound = false
    this.albums = []
    this.albumsCount = 0
    this.albumsPerPage = DefaultAlbumsPerPage
    this.photographsPerPage = DefaultPhotographsPerPage
    this.photographs = []
    this.tagIds = []
    this.tagValue = new InputStore(string().optional())
    this.tagKey = undefined
    this.isFilteringByFaceRecognition = false
    this.albumsView = false
    this.activities = []
    this.activity = null
    this.photographCount = 0
    this.dateRange = [null, null]
    this.isLoadingEventDetails = false
    this.isLoadingEventPhotographs = false
    this.isLoadingEventAlbums = false
    this.isLoadingEventActivities = false
    this.page = 1
    this.error = null
  }
  //FETCH FUNCTIONS
  async fetchEventDetails(): Promise<void> {
    this.startLoadingEventDetails()
    try {
      const response = await this.feedService.fetchEventDetailsInformationByLandingPath(
        this.eventLandingPath
      )
      runInAction(() => {
        this.eventId = response.id
        this.event = response
        if (isNil(this.event.activityId)) {
          this.fetchEventActivities()
        }
        this.stopLoadingEventDetails()
      })
    } catch (e: any) {
      this.eventNotFound = e.message === 'Event not found'
      this.stopLoadingEventDetails()
    }
  }
  async fetchEventAlbums() {
    this.startLoadingEventAlbums()

    const limit = this.albumsPerPage
    const offset = (this.page - 1) * this.albumsPerPage
    try {
      const response = await this.feedService.fetchFeedAlbums({
        limit: limit,
        offset: offset,
        order: {
          field: FeedOrderFields.CREATED_AT,
          sort: FeedOrderSorts.ASC,
        },
        eventId: this.event.id,
        includeHiddenEventAlbums: true,
        dateFrom: this.dateRange[0] ?? undefined,
        dateTo: this.dateRange[1] ?? undefined,
      })

      runInAction(() => {
        this.albums = response.albums
        this.albumsCount = response.count
        this.stopLoadingEventAlbums()
      })
    } catch (e) {
      this.stopLoadingEventAlbums()
      this.error = e
    }
  }

  async fetchEventPhotographs(isSearching = false, tagValue: string): Promise<void> {
    this.startLoadingEventPhotographs()
    if (isSearching) {
      this.page = 1
      this.photographs = []
    }

    if (tagValue) {
      const response = await this.feedService.fetchTag(tagValue)
      if (response) {
        this.tagIds = [response.id]
        this.tagKey = response.key
      } else {
        this.tagIds = []
        this.tagKey = undefined
        this.photographs = []
        this.photographCount = 0
        this.stopLoadingEventPhotographs()
        return
      }
    } else {
      this.tagIds = []
      this.tagKey = undefined
    }

    const limit =
      (this.isFilteringByFaceRecognition || this.tagIds.length > 0) && isSearching
        ? DefaultFilteredPhotographsPerEvent
        : this.photographsPerPage
    const offset = this.isFilteringByFaceRecognition
      ? (this.page - 1) * DefaultFilteredPhotographsPerEvent
      : (this.page - 1) * this.photographsPerPage
    try {
      const response = await this.eventService.fetchEventPhotographs(
        this.event.id,
        limit,
        offset,
        this.tagValue.value,
        this.tagKey,
        this.isFilteringByFaceRecognition ? this.authStore.recognitionImageUrl : undefined,
        this.dateRange[0] ?? undefined,
        this.dateRange[1] ?? undefined,
        this.authStore.isAuthenticated() ? this.authStore.getLoggedUser().id : undefined
      )
      runInAction(() => {
        if (isSearching) {
          this.photographs = response.items
        } else {
          this.photographs.push(...response.items)
        }
        this.photographCount = response.count
        this.stopLoadingEventPhotographs()
      })
    } catch (e) {
      this.stopLoadingEventPhotographs()
    }
  }

  async fetchEventActivities() {
    this.startLoadingEventActivities()
    try {
      const response = await this.eventService.fetchEventActivities(this.event.id)
      runInAction(() => {
        this.activities = response
        this.stopLoadingEventActivities()
      })
    } catch (e) {
      this.stopLoadingEventActivities()
      this.error = e
    }
  }

  async updatePhotographCount(albumPhotographs: number) {
    this.photographCount = this.photographCount + albumPhotographs
  }

  async clearPhotographCount() {
    this.photographCount = 0
  }

  // MODIFICATION FUNCTIONS
  startLoadingEventDetails() {
    this.isLoadingEventDetails = true
  }
  stopLoadingEventDetails() {
    this.isLoadingEventDetails = false
  }
  startLoadingEventPhotographs() {
    this.isLoadingEventPhotographs = true
  }
  stopLoadingEventPhotographs() {
    this.isLoadingEventPhotographs = false
  }
  startLoadingEventAlbums() {
    this.isLoadingEventAlbums = true
  }
  stopLoadingEventAlbums() {
    this.isLoadingEventAlbums = false
  }

  startLoadingEventActivities() {
    this.isLoadingEventActivities = true
  }
  stopLoadingEventActivities() {
    this.isLoadingEventActivities = false
  }

  setPage(page: number) {
    if (page < 1) {
      throw Error(`Page number can't be less than 1`)
    }
    this.page = page
    this.fetchEventPhotographs(false, '')
  }

  setDateRange(range: [string | null, string | null]) {
    runInAction(() => {
      this.dateRange = range
    })
  }

  setTagIds(values: string[]) {
    this.tagIds = values
  }
  setTagValue(value: string) {
    this.tagValue.setValue(value)
  }
  setActivity(activityName: string | undefined) {
    const activity = this.activities.find((activity) => activity.name === activityName) || null
    this.activity = activity
  }
  setIsFilteringByFaceRecognition(value: boolean) {
    this.isFilteringByFaceRecognition = value
    this.clearPhotographCount()
  }

  setAlbumsView(value: boolean) {
    this.albumsView = value
    this.clearPhotographCount()
  }

  fetchNextPage() {
    this.setPage(this.page + 1)
  }

  hasMorePages() {
    const pageCount = Math.ceil(this.photographCount / this.photographsPerPage)
    const areAllAlbumsLoaded = this.isFilteringByFaceRecognition || this.tagValue.value
    return this.page < pageCount && !areAllAlbumsLoaded
  }

  async downloadFreePhotograph(photographId: string) {
    this.isLoadingFreePhotograph = true
    try {
      const photograph = await this.feedService.downloadFreePhotograph(photographId)
      runInAction(() => {
        this.error = null
        this.isLoadingFreePhotograph = false
      })
      return photograph
    } catch (e) {
      this.isLoadingFreePhotograph = false
      this.error = e
    }
  }
}

import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import StoresContext from '../../../providers/storesContext'
import { useTranslation } from 'react-i18next'
import 'moment/locale/es'
import { isNil } from 'lodash'
import BackButton from 'components/UI/Button/BackButton'
import { useQuery } from 'hooks/useQuery'
import Spinner from 'components/UI/Spinner'
import EventInformation from './components/EventInformation'
import EventDetailsStore from './EventDetailsStore'
import axios from 'services/axios'
import withErrorHandler from 'hoc/withErrorHandler/withErrorHandler'
import {
  calculateTotalPhotographsPrice,
  formatPhotographCountText,
  formatUtcToLocaleDate,
  getFeatureFlagValue,
  getFirstNumberOfPicturesForDiscount,
  getQtyDiscountPercentageEarned,
  SetPageTitle,
  updatePackageBarVisibility,
} from '../../../shared/utility'
import Tracker from '../../../shared/tracking'
import * as paths from '../../../routing/Paths'
import EventDetailSearchBar from './components/EventDetailSearchBar'
import FaceRecognitionModal from 'components/AlbumDetails/components/FaceRecognitionModal'
import { PhotographUtils } from 'shared/util/photographs.utils'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import Album from 'shared/models/Album'
import SkeletonLoaderPhotographCard from 'components/UI/Loaders/Skeleton/SkeletonLoaderPhotographCard'
import DetailsPackageBar from 'components/AlbumDetails/components/DetailsPackageBar'
import { Currency } from 'shared/util/Currency'
import { PackageType } from '../../../services/Interfaces/Purchase/PurchaseRequest.interface'
import { TagKeys } from '../../../services/Interfaces/Tag/Tag.interface'
import { toast } from 'react-toastify'
import { FeatureFlags } from 'config/constants/featureFlags'
import EventDetailsContent from './components/EventDetailsContent'
import useHideWhatsappButton from 'hooks/useHideWhatsappButton'
import EventCustomBanner from './components/EventCustomBanner'
import TagFilterModal from 'components/AlbumDetails/components/TagFilterModal'
import EventDetailsFirstRender from './components/FirstRenderFiltersView/EventDetailsFirstRender'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faFilter, faWarning, faXmark } from '@fortawesome/free-solid-svg-icons'
import FirstRenderFiltersViewSkeleton from './components/FirstRenderFiltersView/components/FirstRenderFiltersViewSkeleton'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DateAndTimeFilterModal from 'components/AlbumDetails/components/DateAndTimeFilter/DateAndTimeFilterModal'
import { format, eachDayOfInterval, setHours, setMinutes } from 'date-fns'

type EventDetailsParams = {
  landingPath: string
  photographId: string
}

const EventDetails = () => {
  useHideWhatsappButton()
  const { t } = useTranslation()
  const query = useQuery()
  const queryTags = query.getAll('tags')
  const queryTagValue = query.get('tagValue')
  const history = useHistory()
  const { authStore, cartStore, featureFlagsStore } = useContext(StoresContext)!
  const { landingPath } = useParams<EventDetailsParams>()
  const [store] = useState(
    () => new EventDetailsStore(landingPath, authStore, queryTags, queryTagValue || undefined)
  )

  const {
    event,
    photographs,
    isLoadingEventDetails,
    isLoadingEventAlbums,
    isLoadingEventPhotographs,
  } = store

  const initialRender = useRef(true)

  const [notFound, setNotFound] = useState(false)
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [photographToDisplayId, setPhotographToDisplayId] = useState<string | null>(null)
  const [showUploadSelfieModal, setShowUploadSelfieModal] = useState(false)
  const [showFilterByTagModal, setShowFilterByTagModal] = useState(false)
  const [showFilterByDateAndTimeModal, setShowFilterByDateAndTimeModal] = useState(false)
  const defaultInterval: DropdownOption = { value: '60', label: '60 minutes' }
  const [selectedInterval, setSelectedInterval] = useState<DropdownOption>(defaultInterval)
  const [showBuyPackageButton, setShowBuyPackageButton] = useState(false)
  const [buyNowPrice, setBuyNowPrice] = useState<number | null>(null)
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  const isFilteringByTag = store.tagIds.length === 1

  const albumIdsInPackage = useMemo(
    () => new Set(photographs.map((photo) => photo.albumId)),
    [photographs]
  )
  const albumsInPackage = useMemo(
    () => store.albums.filter((album) => albumIdsInPackage.has(album.id)),
    [albumIdsInPackage, store.albums]
  )
  const ownerIdsInPackage = useMemo(
    () => new Set(albumsInPackage.map((album) => album.owner.id)),
    [albumsInPackage]
  )

  const memoizedValues = useMemo(() => {
    const totalPhotographsPrice = calculateTotalPhotographsPrice(photographs)
    const totalPhotographsPriceAfterDiscounts = !isNil(event.quantityDiscount)
      ? (1 - getQtyDiscountPercentageEarned(event.quantityDiscount, photographs) / 100) *
        totalPhotographsPrice
      : 0
    const minNumberOfPhotosForQtyDiscount = !isNil(event.quantityDiscount)
      ? getFirstNumberOfPicturesForDiscount(event.quantityDiscount)
      : null

    return {
      totalPhotographsPrice,
      totalPhotographsPriceAfterDiscounts,
      minNumberOfPhotosForQtyDiscount,
    }
  }, [photographs, event.defaultPackagePrice, event.quantityDiscount])

  const {
    totalPhotographsPrice,
    totalPhotographsPriceAfterDiscounts,
    minNumberOfPhotosForQtyDiscount,
  } = memoizedValues

  const photographBlocksByTimeInterval = PhotographUtils.buildPhotographsBlocksByInterval(
    photographs,
    selectedInterval
  )

  if (!isNil(event?.name)) {
    SetPageTitle(event.name)
  }

  if (notFound) {
    return <Redirect to="/" />
  }
  const goBack = () => {
    if (history.length > 2) {
      history.goBack()
    } else {
      history.push(paths.EVENTS)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        store.fetchEventDetails().then(() => {
          store.fetchEventAlbums().then(() => {
            if (!isNil(queryTagValue) || !store.event.filtersView) {
              setSearchInProgress(true)
              initialRender.current = false
              store.fetchEventPhotographs(true, queryTagValue ?? '')
            }
          })
        })
      } catch (error: any) {
        if (error.message === 'Albums not found') {
          setNotFound(true)
        }
      }
    }
    if (initialRender.current) {
      fetchData()
    }
  }, [landingPath])

  useEffect(() => {
    if (!store.isLoadingEventPhotographs) {
      const index = photographs.findIndex((photograph) => photograph.id === photographToDisplayId)
      if (index === -1) {
        setPhotographToDisplayId(null)
      } else if (photographs.length - 1 === index && !searchInProgress) {
        store.fetchNextPage()
      }
    }
  }, [photographToDisplayId])

  //PACKAGE PURCHASE

  const handlePackagePurchaseEnabled = getFeatureFlagValue(
    featureFlagsStore,
    FeatureFlags.EVENTS_PACKAGE_PURCHASE,
    false
  )

  useEffect(() => {
    const shouldShowPackageBar = updatePackageBarVisibility(
      isFilteringByTag || store.isFilteringByFaceRecognition,
      store,
      handlePackagePurchaseEnabled,
      totalPhotographsPrice,
      totalPhotographsPriceAfterDiscounts,
      minNumberOfPhotosForQtyDiscount,
      ownerIdsInPackage.size
    )
    setShowBuyPackageButton(shouldShowPackageBar.showBuyPackageButton)
    setBuyNowPrice(shouldShowPackageBar.buyNowPrice)
  }, [photographs])

  const handleBuyPackage = (price: number) => {
    if (isFilteringByTag || store.isFilteringByFaceRecognition) {
      const photographs = store.photographs.map((photograph) => {
        return {
          id: photograph.id,
        }
      })

      const appliesPackagePrice = price === event.defaultPackagePrice

      if (appliesPackagePrice) {
        let tagId: string | undefined
        if (isFilteringByTag) {
          tagId = store.tagIds[0]
        }
        if (store.isFilteringByFaceRecognition) {
          const faceTag = store.photographs
            .map((photograph) => photograph.tags)
            .reduce((acc, tags) => {
              return acc.filter((tag) => tags.some((t) => t.id === tag.id))
            })
            .find((tag) => tag.key === TagKeys.Face)
          if (isNil(faceTag)) {
            toast.error(t('Face tag not found'))
          }
          tagId = faceTag!.id
        }
        Tracker.addPackagePaymentInformation(photographs, event)
        cartStore.createPackagePurchase(photographs, PackageType.Tag, tagId).then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
      } else {
        cartStore.createPackagePurchase(photographs).then((purchase) => {
          if (!isNil(purchase)) {
            window.location.href = purchase.paymentUrl!
          }
        })
      }
    }
  }

  const handleAddPackage = (price: number) => {
    if (store.isFilteringByFaceRecognition) {
      const cartLines = cartStore.getLines()
      const eventPhotographsInCart = cartLines.filter((line) =>
        store.albums.some((album) => album.id === line.photograph.albumId)
      )
      const eventPackageLinesAlreadyInCart = eventPhotographsInCart.some((line) => line.isPackage)

      if (eventPackageLinesAlreadyInCart) {
        toast.error(
          t(
            'There’s already a package from this event with your face in the cart. You can only purchase your photographs by face packages from the same event.'
          ),
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
        return
      }
    }
    if (isFilteringByTag || store.isFilteringByFaceRecognition) {
      let tagId: string | undefined
      if (isFilteringByTag) {
        tagId = store.tagIds[0]
      }

      const photographIds = store.photographs.map((photograph) => photograph.id)

      const filteredPhotographs = store.photographs.filter((photograph) =>
        photographIds.includes(photograph.id)
      )

      const filteredPhotographsIds = filteredPhotographs.flatMap((photograph) => photograph.id)

      const itemsInCartIds = filteredPhotographsIds.filter((photographId) =>
        cartStore.alreadyInCart(photographId)
      )

      const notPackageItemsInCartIds = itemsInCartIds.filter(
        (itemInCartId) => !cartStore.photographFromPackageInCart(itemInCartId)
      )

      if (notPackageItemsInCartIds.length > 0) {
        cartStore.removePhotographs(notPackageItemsInCartIds)
      }

      const packageAlreadyInCart = filteredPhotographsIds.every((photographId) =>
        cartStore.alreadyInCart(photographId)
      )

      const appliesPackagePrice = price === event.defaultPackagePrice

      if (!packageAlreadyInCart) {
        if (store.isFilteringByFaceRecognition) {
          const faceTag = store.photographs
            .map((photograph) => photograph.tags)
            .reduce((acc, tags) => {
              return acc.filter((tag) =>
                tags.some((t) => t.id === tag.id || t.taggedUserId == tag.taggedUserId)
              )
            })
            .find((tag) => tag.key === TagKeys.Face)
          if (isNil(faceTag)) {
            toast.error(t('Face tag not found'))
          }
          tagId = faceTag!.id
        }
        Tracker.addPackagePaymentInformation(photographs, event)
        if (appliesPackagePrice) {
          cartStore.addPackage(
            store.photographs,
            event.currency as Currency,
            price,
            PackageType.Tag,
            tagId
          )
        } else {
          for (const photograph of store.photographs) {
            const album = store.albums.find((album) => album.id === photograph.albumId)
            if (!isNil(album)) {
              cartStore.addPhotograph(photograph, album)
            }
          }
        }
      }
    }
  }

  const updateURLWithFilters = () => {
    const searchParams = new URLSearchParams()
    if (!isNil(store.tagValue) && store.tagValue.value.length > 0) {
      searchParams.append('tagValue', store.tagValue.value)
    }
    const updatedURL = `${location.pathname}?${searchParams.toString()}`
    history.replace(updatedURL)
  }

  const filterByTagValue = () => {
    initialRender.current = false
    store.clearPhotographCount()
    store.setDateRange([null, null])
    store.fetchEventPhotographs(true, store.tagValue.value)
    setShowFilterByTagModal(false)
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const filterByFaceRecognition = () => {
    initialRender.current = false
    store.setTagValue('')
    store.setDateRange([null, null])
    store.clearPhotographCount()
    store.setIsFilteringByFaceRecognition(true)
    store.setAlbumsView(false)
    store.fetchEventPhotographs(true, '')
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const handleSearchByActivity = () => {
    store.fetchEventPhotographs(true, '')
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const filterByDateAndTime = () => {
    initialRender.current = false
    store.setTagValue('')
    store.clearPhotographCount()
    store.fetchEventPhotographs(true, '')
    setShowFilterByDateAndTimeModal(false)
    updateURLWithFilters()
    setSearchInProgress(true)
    setIsAccordionExpanded(false)
  }

  const handleClearFilters = () => {
    initialRender.current = false
    store.setTagValue('')
    store.setDateRange([null, null])
    setShowBuyPackageButton(false)
    store.setIsFilteringByFaceRecognition(false)
    store.setActivity(undefined)
    store.fetchEventPhotographs(true, '')
    store.clearPhotographCount()
    updateURLWithFilters()
    setSearchInProgress(false)
  }

  const handleShowAlbumsView = () => {
    initialRender.current = false
    store.setTagValue('')
    store.setAlbumsView(true)
    store.fetchEventAlbums()
    setSearchInProgress(false)
  }

  const findAlbumForPhotograph = (photographId: string): Album | undefined => {
    const photographIndex = store.photographs.findIndex((photo) => photo.id === photographId)
    if (photographIndex === -1) {
      return undefined
    }

    const albumId = store.photographs[photographIndex].albumId

    const album = store.albums.find((album) => album.id === albumId)
    return album
  }

  const getEventDays = (isAlbumsView: boolean): DropdownOption[] => {
    const firstDate = isAlbumsView ? store.event.firstAlbumDate : store.event.firstPhotographDate
    const lastDate = isAlbumsView ? store.event.lastAlbumDate : store.event.lastPhotographDate
    if (!firstDate || !lastDate) {
      return []
    }

    const start = new Date(firstDate)
    const end = new Date(lastDate)

    if (end <= start) {
      return []
    }

    return eachDayOfInterval({ start, end }).map((date) => ({
      value: date.toISOString(),
      label: formatUtcToLocaleDate(date),
    }))
  }
  const eventDays = getEventDays(store.albumsView)
  const isEventLongerThanFiveDays = (() => {
    const firstDate = event.firstPhotographDate ? new Date(event.firstPhotographDate) : null
    const lastDate = event.lastPhotographDate ? new Date(event.lastPhotographDate) : null
    return firstDate && lastDate
      ? (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24) > 5
      : false
  })()

  const generateTimeOptions = (): DropdownOption[] => {
    const options: DropdownOption[] = []
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = format(setMinutes(setHours(new Date(), hour), minute), 'HH:mm')
        options.push({ value: time, label: time })
      }
    }
    return options
  }

  const timeOptions = generateTimeOptions()

  const loadingSpinner = (
    <Spinner divStyles="flex items-center justify-center mt-5 items-center" size={40} />
  )
  if (cartStore.isLoading) {
    return <Spinner size={40} />
  }

  return (
    <div className="mt-3 mb-24 overflow-hidden">
      <div className="container mx-auto">
        <div className="mb-2 ml-2">
          <BackButton onClick={goBack} />
        </div>
        <div className="rounded-md">
          <EventInformation
            isLoading={isLoadingEventDetails}
            event={event}
            albumsCount={store.albumsCount}
            photographCount={store.photographCount}
            filtersView={initialRender.current && store.event.filtersView}
          />
        </div>
        {initialRender.current && store.event.filtersView ? (
          <EventDetailsFirstRender
            handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
            handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
            filterByFaceRecognition={filterByFaceRecognition}
            filterByTagValue={filterByTagValue}
            handleClearFilters={handleClearFilters}
            handleShowAlbumsView={handleShowAlbumsView}
            store={store}
            isEventLongerThanFiveDays={isEventLongerThanFiveDays}
            filterByDateAndTime={isEventLongerThanFiveDays ? undefined : filterByDateAndTime}
            handleOpenFilterByDateAndTimeModal={
              isEventLongerThanFiveDays ? undefined : () => setShowFilterByDateAndTimeModal(true)
            }
            eventDays={isEventLongerThanFiveDays ? undefined : eventDays}
            timeOptions={isEventLongerThanFiveDays ? undefined : timeOptions}
          />
        ) : (
          <>
            {!isLoadingEventDetails ? (
              <div>
                <Accordion
                  defaultExpanded
                  className="w-full"
                  expanded={isAccordionExpanded}
                  onChange={(event, expanded) => setIsAccordionExpanded(expanded)}
                  sx={{
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    marginTop: '1rem !important',
                    '&.MuiAccordion-root': {
                      borderRadius: '8px',
                      overflow: 'hidden',
                      '&:before': {
                        display: 'none',
                      },
                    },
                    '& .MuiAccordionSummary-root': {
                      backgroundColor: 'white',
                      borderRadius: '8px',
                      minHeight: '48px !important',
                      '&.Mui-expanded': {
                        minHeight: '48px !important',
                      },
                    },
                    '& .MuiAccordionSummary-content': {
                      margin: '12px 0 !important',
                      '&.Mui-expanded': {
                        margin: '12px 0 !important',
                      },
                    },
                    '& .MuiAccordionDetails-root': {
                      borderTop: isAccordionExpanded ? '1px solid #e0e0e0' : 'none',
                      backgroundColor: 'white',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="filters-content"
                    id="filters-header"
                  >
                    <div className="w-full flex justify-between items-center">
                      <div className="flex items-center gap-1.5">
                        <FontAwesomeIcon icon={faFilter} className="text-lumepic-grey lg:text-lg" />
                        <header className="lg:text-lg">{t('Filters')}</header>
                      </div>
                      <div>
                        {(store.tagValue.value?.length > 0 && searchInProgress) ||
                        store.isFilteringByFaceRecognition ||
                        store.activity ||
                        !isNil(store.dateRange[0]) ||
                        !isNil(store.dateRange[1]) ? (
                          <button
                            onClick={() => {
                              handleClearFilters()
                            }}
                            className="w-full sm:w-auto flex gap-2 items-center justify-center px-2 py-1 rounded-md bg-lumepic-light_black hover:lg:scale-105 transition duration-200"
                          >
                            <FontAwesomeIcon icon={faXmark} className="text-white" />
                            <span className="text-sm text-white">{t('Delete filters')}</span>
                          </button>
                        ) : (
                          <span className="text-primary_dark text-sm px-2 py-1">
                            {t('Recommended')}
                          </span>
                        )}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="bg-white w-full p-0">
                    <div className="flex flex-col gap-3 mb-2 lg:px-2.5 pb-2.5 pt-1.5">
                      <EventDetailSearchBar
                        eventDetailsStore={store}
                        handleOpenUploadSelfieModal={() => setShowUploadSelfieModal(true)}
                        handleSearchByActivity={handleSearchByActivity}
                        handleOpenFilterByTagModal={() => setShowFilterByTagModal(true)}
                        handleOpenFilterByDateAndTimeModal={() =>
                          setShowFilterByDateAndTimeModal(true)
                        }
                        searchInProgress={searchInProgress}
                        isEventLongerThanFiveDays={isEventLongerThanFiveDays}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>

                {searchInProgress &&
                  (store.tagValue.value?.length > 0 || store.isFilteringByFaceRecognition) &&
                  !store.isLoadingEventPhotographs && (
                    <>
                      {store.photographCount > 0 ? (
                        <span className="text-lumepic-black md:text-lg align-center md:font-semibold ml-2 pt-2 block">
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="text-lumepic-success_dark_green mr-1 mt-2 lg:mt-3"
                          />
                          {t('You’ve appeared in') +
                            ' ' +
                            formatPhotographCountText(store.photographCount, t)}
                          !
                        </span>
                      ) : (
                        <span className="text-lumepic-black md:text-lg align-center md:font-medium ml-2 pt-2 block">
                          <FontAwesomeIcon
                            icon={faWarning}
                            className="text-lumepic-grey mr-1 mt-2 lg:mt-3"
                          />
                          {t('You’ve not appeared in any photos')}
                        </span>
                      )}
                    </>
                  )}
              </div>
            ) : (
              <FirstRenderFiltersViewSkeleton />
            )}

            <EventDetailsContent
              photographToDisplayId={photographToDisplayId}
              setPhotographToDisplayId={setPhotographToDisplayId}
              store={store}
              findAlbumForPhotograph={findAlbumForPhotograph}
              searchInProgress={searchInProgress}
            />

            {(isLoadingEventAlbums || isLoadingEventPhotographs) && store.page === 1 && (
              <SkeletonLoaderPhotographCard />
            )}
            {(isLoadingEventAlbums || isLoadingEventPhotographs) &&
              store.page > 1 &&
              loadingSpinner}
          </>
        )}
      </div>

      <FaceRecognitionModal
        opened={showUploadSelfieModal}
        onCancel={() => setShowUploadSelfieModal(false)}
        store={store}
        onSuccess={() => filterByFaceRecognition()}
      />

      <TagFilterModal
        opened={showFilterByTagModal}
        onCancel={() => setShowFilterByTagModal(false)}
        store={store}
        onSuccess={() => filterByTagValue()}
        handleClearFilters={() => handleClearFilters()}
      />
      <DateAndTimeFilterModal
        opened={showFilterByDateAndTimeModal}
        onCancel={() => setShowFilterByDateAndTimeModal(false)}
        store={store}
        eventDays={eventDays}
        timeOptions={timeOptions}
        onSuccess={() => filterByDateAndTime()}
        handleClearFilters={() => handleClearFilters()}
      />

      {showBuyPackageButton && !isNil(buyNowPrice) && (
        <DetailsPackageBar
          handleBuyPackage={() => handleBuyPackage(buyNowPrice)}
          handleAddPackage={() => handleAddPackage(buyNowPrice)}
          photographBlocksByInterval={photographBlocksByTimeInterval}
          packagePrice={buyNowPrice}
          event={event}
          isFilteringByFaceRecognition={store.isFilteringByFaceRecognition}
        />
      )}
      {!isNil(event.customBannerSrc) && (
        <EventCustomBanner
          bannerUrl={event.customBannerSrc}
          eventId={event.id}
          eventName={event.name}
        />
      )}
    </div>
  )
}
export default withErrorHandler(observer(EventDetails), axios)

import axios from './axios'
import { AxiosResponse } from 'axios'
import * as paths from '../services/Paths'
import { parseAxiosErrorResponse } from '../shared/utility'
import { LoginRequest, LoginOnBehalfRequest } from './Interfaces/Auth/LoginRequest'
import LoginResponse from './Interfaces/Auth/LoginResponse'
import RegisterRequest from './Interfaces/Auth/RegisterRequest'
import UserInterface from './Interfaces/User/User.interface'
import { CredentialResponse } from '@react-oauth/google'
import VerifyUserRequest from './Interfaces/Auth/VerifyUserRequest'
import VerifyUserResponse from './Interfaces/Auth/VerifyUserResponse'

class AuthService {
  public async login(email: string, password: string): Promise<LoginResponse> {
    const loginData: LoginRequest = {
      email: email,
      password: password,
    }

    return axios.post(paths.LOGIN, loginData).then((response: AxiosResponse) => {
      return response.data as LoginResponse
    })
  }

  public async loginOnBehalf(
    adminEmail: string,
    password: string,
    clientEmail: string
  ): Promise<LoginResponse> {
    const loginData: LoginOnBehalfRequest = {
      adminEmail: adminEmail,
      password: password,
      clientEmail: clientEmail,
    }

    return axios.post(paths.LOGIN_ON_BEHALF, loginData).then((response: AxiosResponse) => {
      return response.data as LoginResponse
    })
  }

  public async register(payload: RegisterRequest): Promise<LoginResponse> {
    return axios.post(paths.REGISTER, payload).then(() => {
      return this.login(payload.email, payload.password)
    })
  }

  async googleAuth(
    credentialResponse: CredentialResponse,
    countryCode?: string,
    randomUuidForRecognitionImage?: string
  ): Promise<LoginResponse> {
    const payload = {
      credential: credentialResponse.credential,
      countryCode: countryCode,
      randomUuidForRecognitionImage: randomUuidForRecognitionImage,
    }
    return axios.post(paths.GOOGLE_AUTH, payload).then((response: AxiosResponse) => {
      return response.data as LoginResponse
    })
  }

  public async verifyUser(token: string): Promise<VerifyUserResponse> {
    const verifyUserData: VerifyUserRequest = {
      verifyUserToken: token,
    }

    return axios.put(paths.VERIFY_USER, verifyUserData).then((response: AxiosResponse) => {
      return response.data as VerifyUserResponse
    })
  }

  fetchProfile(token: string): Promise<UserInterface> {
    return axios
      .get(paths.PROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        localStorage.setItem('userId', response.data.id)
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  forgotPassword(email: string) {
    const requestPayload = {
      email: email,
    }
    return axios
      .post(paths.FORGOT_PASSWORD, requestPayload)
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async requestEmailVerification(
    token: string,
    id: string
  ): Promise<{ user: UserInterface; accountUrl: string }> {
    return axios
      .post<{ user: UserInterface; accountUrl: string }>(
        paths.requestEmailVerification(id),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<{ user: UserInterface; accountUrl: string }>) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async requestPhotographerRole(token: string, id: string): Promise<UserInterface> {
    return axios
      .post<UserInterface>(
        paths.requestPhotographerRole(id),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<UserInterface>) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  async getStripeOnboardingUrl(token: string, id: string): Promise<string> {
    return axios
      .get<{ onboardingUrl: string }>(paths.getStripeOnboardingUrl(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<{ onboardingUrl: string }>) => {
        return response.data.onboardingUrl
      })
      .catch(parseAxiosErrorResponse)
  }

  async getStripeLoginUrl(token: string, id: string): Promise<string> {
    return axios
      .get<{ stripeLoginUrl: string }>(paths.getStripeLoginUrl(id), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<{ stripeLoginUrl: string }>) => {
        return response.data.stripeLoginUrl
      })
      .catch(parseAxiosErrorResponse)
  }

  resetPassword(password: string, token: string) {
    const resetPasswordData = {
      password: password,
      resetToken: token,
    }
    return axios
      .put(paths.RESET_PASSWORD, resetPasswordData)
      .then((response) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  requestMercadoPagoCredentials(token: string, code: string) {
    return axios
      .post(
        paths.VALIDATE_MERCADOPAGO_CREDENTIALS,
        {
          code: code,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default AuthService

import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { Paper, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import ClearIcon from '@mui/icons-material/Clear'
import CountrySwitcherModal from '../CountrySwitcherModal/CountrySwitcherModal'
import { useTranslation } from 'react-i18next'
import Button from '../Button'

const CustomPaperDesktop = (props) => {
  const { t } = useTranslation()
  const [openCountrySwitcherModal, setOpenCountrySwitcherModal] = useState(false)

  const handleCountrySwitcherClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setOpenCountrySwitcherModal(true)
  }

  return (
    <div>
      <div {...props} style={{ color: '#636363', fontFamily: 'Euclid Circular A', width: 200 }}>
        <div
          style={{
            background: '#fff',
            padding: '8px 12px',
            width: 200,
          }}
        >
          <span className="text-lumepic-grey text-sm">{t('Change language')}</span>
        </div>
        <Paper
          {...props}
          style={{ color: '#636363', fontFamily: 'Euclid Circular A', width: 200 }}
          square
        />
        <div
          style={{ background: '#fff', padding: '8px 12px', width: 200 }}
          className="w-3/4 border-t border-lumepic-medium_grey flex justify-center cursor-pointer"
        >
          <Button
            btnType="TertiaryAction"
            extraStyle="text-sm"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleCountrySwitcherClick(e)
            }}
          >
            {t('Change country')}
          </Button>
        </div>
      </div>
      {openCountrySwitcherModal && (
        <CountrySwitcherModal
          open={openCountrySwitcherModal}
          onClose={() => setOpenCountrySwitcherModal(false)}
        />
      )}
    </div>
  )
}

const CustomPaper = (props) => {
  return (
    <Paper
      {...props}
      style={{
        color: '#636363',
        fontFamily: 'Euclid Circular A',
      }}
    />
  )
}

export type DropdownOption = {
  label: string
  value: any
}

interface ExtraStyles {
  input?: {
    color?: string
    paddingRight?: string
    '&::placeholder'?: {
      color?: string
      fontFamily?: string
      fontWeight?: string
      opacity?: number
      paddingRight?: string
    }
  }
  '& .MuiInputBase-root'?: {
    padding?: string
  }
  fieldset?: {
    borderColor?: string
    borderWidth?: string
    color?: string
  }
  faAngleDownStyles?: {
    color?: string
    paddingTop?: string
    paddingRight?: string
  }
}
type DropdownProps = {
  label: string | React.ReactNode
  options: DropdownOption[]
  onChange: (value: { value: any; label: string }) => void
  disabled?: boolean
  value?: { value: string; label: string }
  currentLanguage?: string
  flagIcon?: React.ReactNode
  showFlagIcon?: boolean
  extraStyles?: ExtraStyles
  withClearIcon?: boolean
  noBorderColor?: boolean
  largeWidth?: boolean
  isDesktop?: boolean
}

const Dropdown = (props: DropdownProps) => {
  const [open, setOpen] = useState(false)
  const options = props.options.map((option) => {
    const firstLetter = option.label[0].toUpperCase()
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '' : firstLetter,
      ...option,
    }
  })
  const labelWithIcon = props.showFlagIcon ? (
    <span className="flex items-center">{props.flagIcon}</span>
  ) : null

  const inputStyles = {
    paddingRight: '4px',
    ...(props.extraStyles?.input || {}),
  }

  const dropdownWidth = props.largeWidth ? '160px' : 'auto'

  return (
    <Autocomplete
      id="grouped-demo"
      blurOnSelect
      value={options.find((option) => option.value === props.value?.value)}
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      getOptionLabel={(option) => option.label}
      disabled={props.disabled}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      className={
        props.noBorderColor
          ? ':hover-border-lumepic-light_grey'
          : 'border-2 border-lumepic-grey rounded-md'
      }
      style={{ width: dropdownWidth }}
      size="small"
      PaperComponent={(paperProps) =>
        props.isDesktop ? (
          <CustomPaperDesktop {...paperProps} setOpen={setOpen} />
        ) : (
          <CustomPaper {...paperProps} />
        )
      }
      onChange={(e, v) => props.onChange({ value: v!.value, label: v!.label })}
      popupIcon={
        <>
          {
            <FontAwesomeIcon
              icon={faAngleDown}
              style={{ color: '#999999', paddingTop: '3px', paddingRight: '4px' }}
              size="2xs"
            />
          }
        </>
      }
      clearIcon={props.withClearIcon ? <ClearIcon sx={{ width: '20px' }} /> : null}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.label?.toString()}
          inputProps={{ ...params.inputProps, readOnly: true }}
          sx={{
            input: inputStyles,
            fieldset: {
              border: '0px',
            },
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              paddingRight: '12px',
            },
            startAdornment: <>{labelWithIcon}</>,
          }}
        />
      )}
    />
  )
}

export default Dropdown

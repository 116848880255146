import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import EventDetailsStore from '../EventDetailsStore'
import { PhotographUtils } from 'shared/util/photographs.utils'
import { feedAlbumPhotographDetailById } from 'routing/Paths'
import Album from 'shared/models/Album'
import Tracker from 'shared/tracking'
import NoResultsFound from 'components/UI/NoResultsFound/NoResultsFound'
import { isNil } from 'lodash'
import PhotographModal from 'components/PhotographDetailsModal'
import { observer } from 'mobx-react'
import AlbumCards from 'components/Album/AlbumCards/AlbumCards'
import Masonry from '@mui/lab/Masonry'
import GallerySinglePhoto from 'components/UI/DetailsPhotoGallery/GallerySinglePhoto'
import DetailsPhotographCard from 'components/AlbumDetails/components/DetailsPhotographCard'

type EventDetailsContentProps = {
  photographToDisplayId: string | null
  setPhotographToDisplayId: React.Dispatch<React.SetStateAction<string | null>>
  store: EventDetailsStore
  findAlbumForPhotograph: (photographId: string) => Album | undefined
  searchInProgress: boolean
}

const EventDetailsContent = ({
  setPhotographToDisplayId,
  photographToDisplayId,
  store,
  findAlbumForPhotograph,
  searchInProgress,
}: EventDetailsContentProps) => {
  const {
    albums,
    photographs,
    event,
    tagIds,
    isLoadingEventDetails,
    isLoadingEventPhotographs,
    isLoadingEventAlbums,
  } = store

  const handleDownloadFreePhotograph = (photographId: string) => {
    if (!event.isFree) {
      return
    }
    Tracker.downloadFreePhotograph(
      photographId,
      findAlbumForPhotograph(photographId)!.id,
      event.id,
      event.landingPath
    )
    store.downloadFreePhotograph(photographId).then((response) => {
      if (response) {
        const link = document.createElement('a')
        link.href = response.url
        link.click()
      }
    })
  }

  const setIntervalAccordionPhotographModalPhotograph = (photographId: string) => {
    const url = feedAlbumPhotographDetailById(
      findAlbumForPhotograph(photographId)!.id,
      photographId
    )
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const setPhotograph = (albumId: string, photographId: string) => {
    const url = feedAlbumPhotographDetailById(albumId, photographId)
    window.history.replaceState(null, '', url)
    setPhotographToDisplayId(photographId)
  }

  const closeImageModal = () => {
    setPhotographToDisplayId(null)
    const initialUrl = `/events/${event.landingPath}`
    const tagValueInUrl = tagIds.length > 0 ? `?tagValue=${tagIds}` : ''
    const urlWithTagIds = initialUrl + tagValueInUrl
    tagIds
      ? window.history.replaceState(null, '', urlWithTagIds)
      : window.history.replaceState(null, '', initialUrl)
    if (photographs.length === 1) {
      store.fetchEventPhotographs(true, '')
    }
    PhotographUtils.mantainScrollPositionAfterClosingModal(photographToDisplayId, photographs)
  }

  return (
    <div>
      {store.albumsView ? (
        <div className="mx-4 md:mx-0">
          <InfiniteScroll
            dataLength={store.albums.length}
            next={store.fetchNextPage}
            hasMore={store.hasMorePages()}
            loader={<></>}
            scrollThreshold={0.8}
            className="overflow-hidden"
          >
            <div className="mt-2">
              {albums.length > 0 && <AlbumCards albums={store.albums} eventAlbumsView={true} />}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        photographs.length > 0 && (
          <InfiniteScroll
            dataLength={photographs.length}
            next={() => store.fetchNextPage()}
            hasMore={store.hasMorePages()}
            loader={<></>}
            scrollThreshold={0.95}
            className="overflow-hidden"
          >
            <div className="w-full min-h-primaryMobile bg-white rounded-lg mt-2 p-2">
              {event.isFree ? (
                <Masonry columns={{ xs: 2, sm: 3, md: 4 }} spacing={0.5} className="w-full">
                  {photographs.map((photograph) => (
                    <GallerySinglePhoto
                      key={photograph.id}
                      photograph={photograph}
                      setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                      downloadFreePhotograph={handleDownloadFreePhotograph}
                    />
                  ))}
                </Masonry>
              ) : (
                <div className="w-full grid grid-cols-6 gap-2 mt-2 px-1.5">
                  {photographs.map((photograph) => {
                    const album = !isNil(store)
                      ? albums.find((album) => album.id === photograph.albumId)
                      : null
                    return (
                      <DetailsPhotographCard
                        photograph={photograph}
                        album={album!}
                        eventDetailsStore={store}
                        setPhotograph={setIntervalAccordionPhotographModalPhotograph}
                        key={photograph.id}
                        downloadFreePhotograph={handleDownloadFreePhotograph}
                      />
                    )
                  })}
                </div>
              )}
            </div>
          </InfiniteScroll>
        )
      )}

      {!albums.length &&
        !photographs.length &&
        !isLoadingEventDetails &&
        !isLoadingEventPhotographs &&
        !isLoadingEventAlbums && (
          <NoResultsFound
            emptyEvent={!searchInProgress}
            filteredSearchEmpty={searchInProgress}
            event={event}
          />
        )}

      {!isNil(photographToDisplayId) && (
        <PhotographModal
          photographToDisplayId={photographToDisplayId}
          setPhotographToDisplayId={setPhotograph}
          album={findAlbumForPhotograph(photographToDisplayId)!}
          photographs={photographs}
          loadingNextPage={isLoadingEventPhotographs}
          onClose={closeImageModal}
          onFeed
          onDownloadFreePhotograph={handleDownloadFreePhotograph}
        />
      )}
    </div>
  )
}
export default observer(EventDetailsContent)

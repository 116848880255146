import {
  faChartLine,
  faCircleDollarToSlot,
  faCloudArrowUp,
  faImage,
  faPercentage,
  faTag,
  faUpload,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import SearchBar from 'components/UI/SearchBar'
import SearchBarStore from 'components/UI/SearchBar/SearchBarStore'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhotographerMetricsInterface from 'services/Interfaces/User/PhotographerMetrics.interface'
import { Filters } from 'components/UI/SearchBar/SearchBar'
import MetricCard from './components/MetricCard'
import { formatThousandsAmount } from 'shared/utility'
import Modal from 'components/UI/Modal'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'

type PhotographerMetricsProps = {
  metrics: PhotographerMetricsInterface
  searchBarStore: SearchBarStore
  handleSearch: () => void
  filters: Filters[]
  scrollToClients: () => void
}

const PhotographerMetrics = ({
  metrics,
  searchBarStore,
  handleSearch,
  filters,
  scrollToClients,
}: PhotographerMetricsProps) => {
  const { t } = useTranslation()
  const [showEarningsPerCurrencyModal, setShowEarningsPerCurrencyModal] = useState(false)

  const metricsList = [
    {
      icon: faCircleDollarToSlot,
      value: formatThousandsAmount(metrics.earnings.totalEarningsInUsd ?? 0, 2),
      label: t('Total Earnings (USD)'),
      showDetailsButton: true,
      onDetailsClick: () => setShowEarningsPerCurrencyModal(true),
    },
    {
      icon: faChartLine,
      value: formatThousandsAmount(metrics.salesCount ?? 0, 0),
      label: t('Sales'),
    },
    {
      icon: faUsers,
      value: formatThousandsAmount(metrics.clientsCount ?? 0, 0),
      label: t('Loyal Customers'),
      description: t(
        'Number of customers who purchased using your fidelity code (Lumepic will not charge you a commission for them for one year)'
      ),
      showDetailsButton: metrics.clientsCount ? true : false,
      onDetailsClick: scrollToClients,
    },
    {
      icon: faTag,
      value: formatThousandsAmount(metrics.averageTicket ?? 0, 2),
      label: t('Average Sale Ticket (USD)'),
    },
    {
      icon: faCloudArrowUp,
      value: formatThousandsAmount(metrics.albumsCount, 0),
      label: t('Published Albums'),
    },
    {
      icon: faUpload,
      value: formatThousandsAmount(metrics.numberOfUploadedPhotographs, 0),
      label: t('Published Photographs'),
    },
    {
      icon: faImage,
      value: formatThousandsAmount(metrics.numberOfSoldPhotographs, 0),
      label: t('Photographs Sold'),
    },
    {
      icon: faPercentage,
      value: formatThousandsAmount(metrics.rating * 100, 2),
      label: t('Ratio (%)'),
      description: t('Ratio of photographs sold to photographs uploaded'),
    },
  ]

  const totalPerCurrency = Object.entries(metrics.earnings.earningsByCurrency).map(
    ([currency, amount], index) => (
      <tr key={currency} className={index % 2 === 0 ? 'bg-white' : 'bg-bg_details'}>
        <td className="px-4 py-2 text-lumepic-light_black font-lumepicRegular border-b border-gray-300">
          {currency.toUpperCase()}
        </td>
        <td className="px-4 py-2 text-lumepic-light_black font-lumepicRegular border-b border-gray-300">
          {formatThousandsAmount(amount)}
        </td>
      </tr>
    )
  )

  return (
    <div className="pb-2">
      <div className="pb-0.5 px-2">
        <SearchBar searchBarStore={searchBarStore} onSearch={handleSearch} filters={filters} />
      </div>
      <div className="w-full justify-center gap-1 grid grid-cols-4 grid-rows-2 md:gap-4 px-2">
        {metricsList.map(
          ({ icon, value, label, description, showDetailsButton, onDetailsClick }) => (
            <MetricCard
              key={label}
              icon={icon}
              value={value}
              label={label}
              description={description}
              showDetailsButton={showDetailsButton}
              onDetailsClick={onDetailsClick}
            />
          )
        )}
      </div>
      <Modal
        opened={showEarningsPerCurrencyModal}
        onCancel={() => setShowEarningsPerCurrencyModal(false)}
        modalSize={ModalSize.SMALL}
        buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      >
        <div className="p-4 w-full h-full flex flex-col items-center justify-center">
          <h2 className="text-xl text-lumepic-light_black mb-4">{t('Earnings By Currency')}</h2>
          <table className="border-collapse border border-gray-300 rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left text-lumepic-light_black font-semibold border-b border-gray-300">
                  {t('Currency')}
                </th>
                <th className="px-4 py-2 text-left text-lumepic-light_black font-semibold border-b border-gray-300">
                  {t('Total')}
                </th>
              </tr>
            </thead>
            <tbody>{totalPerCurrency}</tbody>
          </table>
        </div>
      </Modal>
    </div>
  )
}

export default PhotographerMetrics

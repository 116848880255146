import { CredentialResponse } from '@react-oauth/google'
import { makeAutoObservable, runInAction } from 'mobx'
import RegisterPhotographerRequest from 'services/Interfaces/Auth/RegisterPhotographerRequest'
import { RoleType } from 'services/Interfaces/Role/Role.interface'
import { Role, User } from 'shared/models/User'
import AuthStore from 'stores/AuthStore'
import LocationStore from 'stores/LocationStore'

class PhotographerRegisterStore {
  public isLoading: boolean
  public error: any
  constructor(
    private readonly authStore: AuthStore,
    private readonly locationStore: LocationStore
  ) {
    this.reset()
    makeAutoObservable(this)
  }

  reset() {
    this.isLoading = false
    this.error = null
  }

  async register(photographerRegisterPayload: RegisterPhotographerRequest) {
    runInAction(() => {
      this.isLoading = true
      this.error = null
    })
    try {
      const newUser = new User()
      newUser.email = photographerRegisterPayload.email
      newUser.password = photographerRegisterPayload.password
      newUser.firstName = photographerRegisterPayload.firstName
      newUser.lastName = photographerRegisterPayload.lastName
      newUser.countryCode = photographerRegisterPayload.countryCode
      newUser.roles = []

      const athleteRole = new Role()
      athleteRole.type = RoleType.ATHLETE
      newUser.roles.push(athleteRole)

      const photographerRole = new Role()
      photographerRole.type = RoleType.PHOTOGRAPHER
      newUser.roles.push(photographerRole)

      await this.authStore.register(newUser)

      runInAction(() => {
        this.isLoading = false
        this.error = null
      })

      return true
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e.response?.data?.errors || {}
      })
    }
  }

  async googleAuth(credentialResponse: CredentialResponse): Promise<void> {
    const countryCode = this.locationStore.userLocation.country.code
    await this.authStore.googleAuth(credentialResponse, countryCode)
  }
}

export default PhotographerRegisterStore

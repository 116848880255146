import React from 'react'
import Modal from 'components/UI/Modal'
import { observer } from 'mobx-react'
import { ModalButtonsDisplay, ModalSize } from 'components/UI/Modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import backgroundImage from '../../../../assets/images/goldenBg1.svg'
import { DropdownOption } from 'components/UI/Dropdown/Dropdown'
import DateAndFilterContent from './DateAndTimeFilterContent'
import EventDetailsStore from 'containers/Events/EventDetails/EventDetailsStore'
import { useTranslation } from 'react-i18next'

type DateAndTimeFilterModalProps = {
  onCancel: () => void
  opened: boolean
  onSuccess: () => void
  handleClearFilters: () => void
  eventDays: DropdownOption[]
  timeOptions: DropdownOption[]
  store: EventDetailsStore
}

const DateAndTimeFilterModal = ({
  onCancel,
  onSuccess,
  opened,
  store,
  eventDays,
  timeOptions,
}: DateAndTimeFilterModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      onCancel={onCancel}
      opened={opened}
      modalSize={ModalSize.MEDIUM_SMALL}
      buttonsDisplay={ModalButtonsDisplay.NO_BUTTONS}
      displayCloseIcon={false}
    >
      <div className="w-full">
        <div className="relative overflow-hidden h-14 rounded-t-md">
          <img src={backgroundImage} className="w-full" alt="background" />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="absolute text-xl cursor-pointer top-2 right-2"
            onClick={onCancel}
          />
        </div>
        <div className="relative h-auto">
          <div className="absolute w-full text-center -top-6">
            <div className="flex flex-col justify-center gap-2">
              <h3 className="mb-0 text-center">
                <span className="p-1 text-2xl font-medium bg-white rounded-md shadow-md">
                  {eventDays.length > 1 ? t('Filter by date and time') : t('Filter by hour')}
                </span>
              </h3>
            </div>
          </div>
        </div>
        <DateAndFilterContent
          eventDays={eventDays}
          timeOptions={timeOptions}
          onSuccess={onSuccess}
          store={store}
        />
      </div>
    </Modal>
  )
}

export default observer(DateAndTimeFilterModal)
